export class CompanyModel
    {

        companyId= 0;
        companyName= "";
        companyCode= "";
        companyTypeId= 0;
        companyTypeName= "";
        parentCompanyId= 0;
        email= "";
        website= "";
        notes= "";
        createdBy= 0;
        dateCreated?= "";
        modifiedBy= 0;
        dateModified= "";
        deletedBy= 0;
        dateDeleted= "2023-03-31T05:20:47.224Z";
        isDeleted= true;
        officeId= 0;
        companyApprovalStatus= "";
        ownerId= 0;
        cafReceived= true;
        cafReceivedDate= "2023-03-31T05:20:47.224Z";
        creditDays= "";
        mepzCode= "";
        pannumber= "";
        gsttypeId= 0;
        gstin= "";
        pan= "";
        paymentTermId= 0;
        paymentTermLabel= "";
        gsttypeName= "";
        companyType?= null


}
export class Address
{
    
        companyAddressId= 0;
        companyId= 0;
        contactId= null;
        addressTypeId= 0;
        addressTypeNick= "";
        countryId= 0;
        country= "";
        stateId= null;
        stateName= "";
        cityId= null;
        cityName= null;
        addressLine1= null;
        addressLine2= "";
        zipcode= null;
        relatedPortId= null;
        relatedAirportId= null;
        formattedAddress= "";
        createdBy= 0;
        dateCreated= "2023-03-27T19:33:41.14";
        modifiedBy= null;
        dateModified= null;
        deletedBy= null;
        dateDeleted= null;
        isDeleted= false;
        phone1= null;
        phone2= null;
        faxnumber= null;
        gstnumber= "";
        city= null;
        company= null;
        countryNavigation= null;
        relatedAirport= null;
        relatedPort= null;
        state= null
     
}
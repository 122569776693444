export class CompanyList{

        companyId= 0;
        companyName= "";
        companyCode= "";
        companyTypeId= 0;
        companyTypeName= "";
        parentCompanyId= 0;
        email= "";
        website= "";
        notes= "";
        createdBy= 0;
        dateCreated?= "";
        modifiedBy= 0;
        dateModified= "";
        deletedBy= 0;
        dateDeleted= "";
        isDeleted= true;
        officeId= 0;
        companyApprovalStatus= "";
        ownerId= 0;
        cafReceived= true;
        cafReceivedDate= "";
        creditDays= "";
        mepzCode= "";
        pannumber= "";
        gsttypeId= 0;
        gstin= "";
        pan= "";
        paymentTermId= 0;
        paymentTermLabel= "";
        gsttypeName= "";
        companyType?= null
      }   

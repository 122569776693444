<div class="content-wrapper container-xxl p-0">
  <div class="col"> <h3 class="card-title">Sales Quotes List</h3></div> 
  <div class="card container">
    <div class="cardbody pl-2 pr-2">
 
        <app-pricing-sales-quotes-list></app-pricing-sales-quotes-list>
        
        <router-outlet></router-outlet>
  </div>
  
  </div>
  
  
  
  
  
  
  
  
  
  
  
<div class="content-wrapper container-xxl p-0">

      <!-- Modal -->
      <ng-template #modalForm let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel1">New Air Export Cargo</h4>
          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
          <form action="#">
            <label>Email: </label>
            <div class="form-group">
              <input type="text" placeholder="Email Address" class="form-control" />
            </div>
            <label>Password: </label>
            <div class="form-group">
              <input type="password" placeholder="Password" class="form-control" />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            Login
          </button>
        </div>
      </ng-template>
      <!-- / Modal -->

          <!-- Modal -->
          <ng-template #modalXL let-modal>
            <div class="modal-header">
              <h5 class="modal-title" id="myModalLabel160">New Air Export Cargo</h5>
              <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div class="col-12">
                    <div class="card-body">
                        <div class="row">
                          <div class="col-4">
                            <form [formGroup]="ReactiveUserDetailsForm" (ngSubmit)="ReactiveUDFormOnSubmit()">
                              <h6 class="mb-2">Customer Details</h6>

                              <div class="form-group">
                                <label for="basicSelect">Customer Name</label>
                                <select class="form-control" id="basicSelect">
                                  <option>XLPXL</option>
                                  <option>RING PLUS</option>
                                  <option>CLASSIC MARBLE PVT LTD</option>
                                  <option>PV CLEAN</option>
                                  <option>AVARY</option>
                                </select>
                              </div>
                              <div class="form-group">
                                <label for="basicSelect">Sales Person</label>
                                <select class="form-control" id="basicSelect">
                                  <option *ngFor="">Pradeep Alwar</option>
                                  <option>Gauri Kamble</option>
                                  <option>Mayur Bhanushali</option>
                                  <option>Deepa Badap</option>
                                  <option>Muharrem Dursun</option>
                                  <option>Vishal Kamble </option>
                                  <option>Deepak Kamble </option>
                                  <option>Pratibha Pojary</option>
                                  <option>Meenal Patne</option>
                                </select>
                              </div>
                              <label>Basic Select with Search</label>
                              <div class="form-group">
                                <!-- Basic Select with Search -->
                                <ng-select [items]="selectBasic" bindLabel="UserDisplayName" [loading]="selectBasicLoading"> </ng-select>
                                <!--/ Basic Select with Search -->
                              </div>
      
                              <div class="form-group">
                                <label for="basicInput">Estimated Amount(INR)</label>
                                <input type="text" class="form-control" id="basicInput" placeholder="Estimated Amount" />
                              </div>

                              <h6 class="mb-2">Customer Details</h6>
                            
                            </form>
                          </div>
                          <div class="col-8">
                            B
                          </div>
                        </div>
                      </div>
                  </div>
              <!-- <div class="row">
                    <div class="col-4">
                        <div class="badge badge-pill badge-secondary">Customer Details</div>
                        <form action="#">
                            <label>Email: </label>
                            <div class="form-group">
                              <input type="text" placeholder="Email Address" class="form-control" />
                            </div>
                            <label>Password: </label>
                            <div class="form-group">
                              <input type="password" placeholder="Password" class="form-control" />
                            </div>
                            <label>Password: </label>
                            <div class="form-group">
                              <input type="password" placeholder="Password" class="form-control" />
                            </div>
                            <label>Password: </label>
                            <div class="form-group">
                              <input type="password" placeholder="Password" class="form-control" />
                            </div>
                            <label>Password: </label>
                            <div class="form-group">
                              <input type="password" placeholder="Password" class="form-control" />
                            </div>
                            <label>Password: </label>
                            <div class="form-group">
                              <input type="password" placeholder="Password" class="form-control" />
                            </div>
                           
                          </form>
                    </div>
                    <div class="col-8"></div>
             
              </div> -->
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
                Accept
              </button>
            </div>
          </ng-template>
          <!-- / Modal -->
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <!-- Kitchen Sink Datatables -->
      <section id="ngx-datatable-kitchen-sink">
        <!-- <core-card-snippet [snippetCode]="_snippetCodeKitchenSink"> -->
          <div class="row">
            <div class="col-12 mb-50">
                <button type="button" class="btn btn-primary ml-1" rippleEffect (click)="modalOpenXL(modalXL)" >
                    <i data-feather="plus" class="mr-0 mr-sm-1"></i
                    ><span class="d-none d-sm-inline-block">New Cargo</span>
                  </button>
  
              <!-- <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" rippleEffect
                >Export CSV</a> -->
            </div>
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center"
                  >Show
                  <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries</label
                >
              </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
              <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center"
                  >Search<input
                    type="search"
                    placeholder="Search"
                    class="form-control ml-25"
                    (keyup)="filterUpdate($event)"
                    (search)="filterUpdate($event)"
                /></label>
              </div>
            </div>
          </div>
          <ngx-datatable
            [rows]="kitchenSinkRows"
            [rowHeight]="58"
            class="bootstrap core-bootstrap"
            [limit]="10"
            [columnMode]="ColumnMode.force"
            [headerHeight]="40"
            [footerHeight]="50"
            [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"
            (activate)="onActivate($event)"
            (select)="onSelect($event)"
          >
            <ngx-datatable-column
              [width]="50"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)"
                    id="headerChkbxRef"
                  />
                  <label class="custom-control-label" for="headerChkbxRef"></label>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="isSelected"
                    (change)="onCheckboxChangeFn($event)"
                    id="rowChkbxRef{{ rowIndex }}"
                  />
                  <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Job No" prop="jobNo" [width]="280">
            </ngx-datatable-column>
            <ngx-datatable-column name="Shipper" prop="shipper" [width]="250"></ngx-datatable-column>
            <ngx-datatable-column name="Consignee" prop="consignee" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="POL" prop="pol" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="POD" prop="pod" [width]="120">
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
              <ng-template ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div ngbDropdown container="body">
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="hide-arrow"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                        ><i data-feather="file-text" class="mr-50"></i> Details</a
                      >
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                        ><i data-feather="archive" class="mr-50"></i> Archive</a
                      >
                      <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                        ><i data-feather="trash-2" class="mr-50"></i> Delete</a
                      >
                    </div>
                  </div>
  
                  <i data-feather="edit" class="text-primary cursor-pointer"></i>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        <!-- </core-card-snippet> -->
      </section>
      <!--/ Kitchen Sink Datatables -->
    </div>
  </div>
  
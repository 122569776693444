<!-- <section id="ngx-datatable-kitchen-sink" class="">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink"> -->
   
     
  <div class="row">
    <div class="col-12 mb-50 d-flex">
      <!-- <div class="col"> <h4 class="card-title">Sales Quotes List</h4></div> -->
      <!-- <div class="col text-right">
        <button class="btn btn-primary ml-1" rippleEffect (click)="onShowQuoteForm()">
          <i data-feather="plus" class="mr-0 mr-sm-1"></i
          ><span class="d-none d-sm-inline-block" >New Sales Quote</span>
        </button>
      </div> -->
        <!-- <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary float-right mr-1" rippleEffect
          >Export CSV</a
        > -->
    </div>
    <div class="col-md-6 col-12">
      <div class="d-flex justify-content-between align-items-center m-1">
        <label class="d-flex align-items-center"
          >Show
          <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries</label
        >
      </div>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-md-end">
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <label class="d-flex align-items-center"
          >Search<input
            type="search"
            placeholder="Search"
            class="form-control ml-25"
            (keyup)="filterUpdate($event)"
            (search)="filterUpdate($event)"
        /></label>
      </div>
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <button class="btn btn-primary ml-1" rippleEffect (click)="onShowQuoteForm()">
          <i data-feather="plus" class="mr-0 mr-sm-1"></i
          ><span class="d-none d-sm-inline-block" >New Sales Quote</span>
        </button>
      <i class="fa fa-file-excel-o ml-1 d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0 cursor-pointer"  style="font-size:15px;color:green" (click)="exportAsXLSX()"></i>
        
      </div>
    </div>
  </div>
  
  
  
  <ngx-datatable
    [rows]="kitchenSinkRows"
    [rowHeight]="58"
    class="bootstrap core-bootstrap p-2"
    [limit]="10"
    
    [headerHeight]="40"
    [footerHeight]="50"
    [scrollbarH]="true"
    [selectionType]="SelectionType.checkbox"
    [limit]="basicSelectedOption"
  
    
  >
  <!-- (select)="onSelect($event)"
    (activate)="onActivate($event)"
  [columnMode]="ColumnMode.force" -->
  
    <ngx-datatable-column
      [width]="50"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <div class="custom-control custom-checkboxv ">
          <input
            type="checkbox"
            class="custom-control-input"
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)"
            id="headerChkbxRef"
          />
          <label class="custom-control-label" for="headerChkbxRef"></label>
        </div>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)"
            id="rowChkbxRef{{ rowIndex }}"
          />
          <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column  name="Sales Quote #" prop="salesQuoteId" [width]="150"></ngx-datatable-column>
    <ngx-datatable-column  name="Sales Quote Number"  prop="salesQuoteNumber" [width]="200">
      <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
          <div routerLink="/sales-quotes-form/{{row.salesQuoteId}}" class="cursor-pointer"><b>{{row.salesQuoteNumber}}</b></div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Sales Quote Date" prop="salesQuoteDate" [width]="200">
      <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
      <!-- {{formatdate | date:'short'}} -->
    </ngx-datatable-column>
    <ngx-datatable-column name="Type" prop="salesQuoteType" [width]="150"></ngx-datatable-column>
    <ngx-datatable-column name="Sales Person" prop="userName" [width]="150"></ngx-datatable-column>
    <ngx-datatable-column name="Mode" prop="modeoftransport" [width]="150"></ngx-datatable-column>
    <ngx-datatable-column name="Direction" prop="direction" [width]="150"></ngx-datatable-column>
    <ngx-datatable-column name="Company Name" prop="companyName" [width]="150"></ngx-datatable-column>
    
    <ngx-datatable-column name="Contact" prop="displayName" [width]="150"></ngx-datatable-column>
    <!-- <ngx-datatable-column name="enquiryDate" prop="enquiryDate" [width]="150"></ngx-datatable-column> -->
    <ngx-datatable-column name="Enquiry Date" prop="enqReceivedDate" [width]="150">
    
      <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
      <!-- {{formatdate | date:'short'}} -->
      </ngx-datatable-column>
    <ngx-datatable-column name="expiryDate" prop="expiryDate" [width]="150">
    
    <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}</ng-template>
    <!-- {{formatdate | date:'short'}} -->
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="revisionId" prop="revisionId" [width]="150"></ngx-datatable-column> -->
    <!-- <ng-template let-value="value" ngx-datatable-cell-template>{{myDate | date:'dd-MM-yyyy'}}</ng-template>
           
          </ngx-datatable-column> -->
    <!-- <ngx-datatable-column name="commodity" prop="commodity" [width]="250"></ngx-datatable-column> -->
    
    
    <ngx-datatable-column name="Edit" [width]="50" [sortable]="false"><a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
      ><i data-feather="trash-2" class="ml-50"></i></a>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div class="d-flex align-items-center">
          
  
          <div class="d-flex align-items-center"  routerLink="/sales-quotes-form/{{row.salesQuoteId}}">
          <span  class="d-flex align-items-center"
      ><i data-feather="edit" class="text-primary cursor-pointer"></i></span>
        </div>
          
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Delete" [width]="100" [sortable]="false"><i data-feather="edit" class="text-primary cursor-pointer"></i>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div class="d-flex align-items-center cursor-pointer text-danger" (click)="onDelete(row.salesQuoteId)">
          <span  class="d-flex align-items-center"
      ><i data-feather="trash-2" class="mr-50"></i></span>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="Edit" [width]="50" [sortable]="false"><a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
      ><i data-feather="trash-2" class="ml-50"></i></a>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center" (click)="onEdit(modal)" routerLink="/sales-quotes-form/{{row.salesQuoteId}}">
          <span  class="d-flex align-items-center">ADD Sales Quotes Details</span>
        </div>
        </div>
      </ng-template>
    </ngx-datatable-column> -->
  </ngx-datatable>
  <!-- </core-card-snippet>
  </section> -->



<div class="content-wrapper container-xxl p-0">

    <section id="ngx-datatable-kitchen-sink">
        <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
          <h4 class="card-title">Ocean Import List</h4>
      <div class="row">
        
        <div class="col-md-6 col-12">
          <div class="d-flex justify-content-between align-items-center m-1">
            <label class="d-flex align-items-center"
              >Show
              <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries</label
            >
          </div>
        </div>
         <div class="col-md-6 col-12 d-flex justify-content-md-end">
          <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
            <label class="d-flex align-items-center"
              >Search<input
                type="search"
                placeholder="Search"
                class="form-control ml-25"
                (keyup)="filterUpdate($event)"
                (search)="filterUpdate($event)"
            /></label>
            <button class="btn btn-gradient-primary ml-2" routerLink="/oidetails"><i data-feather="plus" class="mr-0 mr-sm-1"></i>New Ocean Import</button>
          </div>
        </div>
      </div>
      
      
      
      <ngx-datatable
        [rows]="kitchenSinkRows"
        [rowHeight]="58"
        class="bootstrap core-bootstrap"
        [limit]="10"
        
        [headerHeight]="40"
        [footerHeight]="50"
        [scrollbarH]="true"
        [selectionType]="SelectionType.checkbox"
        [limit]="basicSelectedOption"
      
        
      >
      <!-- (select)="onSelect($event)"
        (activate)="onActivate($event)"
      [columnMode]="ColumnMode.force" -->
      
        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-header-template
            let-value="value"
            let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)"
                id="headerChkbxRef"
              />
              <label class="custom-control-label" for="headerChkbxRef"></label>
            </div>
          </ng-template>
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)"
                id="rowChkbxRef{{ rowIndex }}"
              />
              <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Job No " prop="jobNo" [width]="120">
        
          <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
            <div class="d-flex align-items-center">                  
              <div class="cell-line-height">
                <a  routerLink="/oidetails/{{row.cargoId}}" class="font-medium-1 d-block"
                  ><span class="font-weight-bold">{{ name }}</span></a>
              </div>
            </div>
          </ng-template>

        </ngx-datatable-column>
        <ngx-datatable-column name="Transport Direction " prop="transportDirection" [width]="180">
        
        </ngx-datatable-column>
        <ngx-datatable-column name="mode Of Transport" prop="modeOfTransport" [width]="180"></ngx-datatable-column>
        <ngx-datatable-column name="consignee " prop="consignee" [width]="290"></ngx-datatable-column>
        <ngx-datatable-column name="shipper" prop="shipper" [width]="290"></ngx-datatable-column>
        <ngx-datatable-column name="pol " prop="pol" [width]="200"></ngx-datatable-column>
        <ngx-datatable-column name="pod" prop="pod" [width]="200"></ngx-datatable-column>
        <ngx-datatable-column name="dateCreated " prop="dateCreated" [width]="200"></ngx-datatable-column>
        <ngx-datatable-column name="dateModified" prop="dateModified" [width]="200"></ngx-datatable-column>
        
      </ngx-datatable>
        
      </core-card-snippet>
      </section>
      

</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-detail-modal-form',
  templateUrl: './sales-detail-modal-form.component.html',
  styleUrls: ['./sales-detail-modal-form.component.scss']
})
export class SalesDetailModalFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="content-wrapper container-xxl p-0">
  <hr><hr>
    <ngx-datatable
    class="bootstrap core-bootstrap"
    [headerHeight]="40"
    [rowHeight]="58"
    [limit]="10"
    [columnMode]="ColumnMode.force"
    [footerHeight]="50"
    [rows]="rows"
    [scrollbarH]="true"
  >


  <ngx-datatable-column name="Status" prop="cargoStatusName" [width]="320"></ngx-datatable-column>

    <ngx-datatable-column [width]="120" name="Start Date" prop="cargoStatusDate">
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
        <div
          title="Double click to edit"
          (dblclick)="cargoStatusDate[rowIndex + '-cargoStatusDate'] = true"
          *ngIf="!cargoStatusDate[rowIndex + '-cargoStatusDate']"
        >
        {{ value }}
        </div>
        <input
        autofocus
        class="form-control form-control-sm"
        (blur)="inlineEditingUpdatecargoStatusDate($event, 'cargoStatusDate', rowIndex)"
        *ngIf="cargoStatusDate[rowIndex + '-cargoStatusDate']"
        type="datetime-local"
        [value]="value"
      />

      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [width]="150" name="Remarks" prop="statusRemarks">
      <ng-template ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">
        <div
          title="Double click to edit"
          (dblclick)="editingstatusRemarks[rowIndex + '-statusRemarks'] = true"
          *ngIf="!editingstatusRemarks[rowIndex + '-statusRemarks']"
        >
          {{ value }}
        </div>
        <input
          autofocus
          class="form-control form-control-sm"
          (blur)="inlineEditingUpdatestatusRemarks($event, 'statusRemarks', rowIndex)"
          *ngIf="editingstatusRemarks[rowIndex + '-statusRemarks']"
          type="text"
          [value]="value"
        />
      </ng-template>
    </ngx-datatable-column>
   
      
      
    <ngx-datatable-column [width]="100" name="Updated By" prop="modifiedBy" [width]="120">
      </ngx-datatable-column>
      <ngx-datatable-column [width]="100" name="Updated On" prop="dateModifed" [width]="120">
        <ng-template let-value="value" ngx-datatable-cell-template>{{value |date:'dd-MM-yyyy HH:MM'}}</ng-template>
      </ngx-datatable-column>



      <ngx-datatable-column name="Actions" [width]="100" [sortable]="false" prop="id">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <button class="btn btn-gradient-primary ml-1 me-2" (click)="onSubmitService(row.cargoStatusId)"
            [swal]="SweetAlertConfirmColor" rippleEffect>
            <span class="d-none d-sm-inline-block ">Save</span>
        </button>
        </ng-template>
    </ngx-datatable-column>
    
  </ngx-datatable> 
  <!-- <ngx-datatable
  class="bootstrap core-bootstrap"
  [headerHeight]="40"
  [rowHeight]="58"
  [limit]="10"
  [columnMode]="ColumnMode.force"
  [footerHeight]="50"
  [rows]="rows"
  [scrollbarH]="true"
 
>


<ngx-datatable-column name="Status" prop="cargoStatusName" [width]="320"></ngx-datatable-column>

  <ngx-datatable-column [width]="120" name="Start Date" prop="cargoStatusDate">
    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
      <div
        title="Double click to edit"
        (dblclick)="cargoStatusDate[rowIndex + '-cargoStatusDate'] = true"
        *ngIf="!cargoStatusDate[rowIndex + '-cargoStatusDate']"
      >
      {{ value }}
      </div>
      <input
      autofocus
      class="form-control form-control-sm"
      (blur)="inlineEditingUpdatecargoStatusDate($event, 'cargoStatusDate', rowIndex)"
      *ngIf="cargoStatusDate[rowIndex + '-cargoStatusDate']"
      type="datetime-local"
      [value]="value"
     
    />

    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [width]="150" name="Remarks" prop="statusRemarks">
    <ng-template ngx-datatable-cell-template let-value="value" let-rowIndex="rowIndex" let-row="row">
      <div
        title="Double click to edit"
        (dblclick)="editingstatusRemarks[rowIndex + '-statusRemarks'] = true"
        *ngIf="!editingstatusRemarks[rowIndex + '-statusRemarks']"
      >
        {{ value }}
      </div>
      <input
        autofocus
        class="form-control form-control-sm"
        (blur)="inlineEditingUpdatestatusRemarks($event, 'statusRemarks', rowIndex)"
        *ngIf="editingstatusRemarks[rowIndex + '-statusRemarks']"
        type="text"
        [value]="value"
       
      />
    </ng-template>
  </ngx-datatable-column>
 
    
    
  <ngx-datatable-column [width]="100" name="Updated By" prop="modifiedBy" [width]="120">
    </ngx-datatable-column>
    <ngx-datatable-column [width]="100" name="Updated On" prop="dateModifed" [width]="120">
      <ng-template let-value="value" ngx-datatable-cell-template>{{value |date:'dd-MM-yyyy HH:MM'}}</ng-template>
    </ngx-datatable-column>



    <ngx-datatable-column name="Actions" [width]="100" [sortable]="false" prop="id">
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <button class="btn btn-gradient-primary ml-1 me-2" (click)="onSubmitService(row.cargoStatusId)"
          [swal]="SweetAlertConfirmColor" rippleEffect>
          <span class="d-none d-sm-inline-block ">Save</span>
      </button>
      </ng-template>
  </ngx-datatable-column>
  
</ngx-datatable>-->
</div>


export class Company{

    companyId=0;
    companyName="";
    displayName : "";
    companyTypeId: "";
    companyTypeName: "";
    parentCompanyId: "";
    parentCompanyName: "";
    gstNumber: "";
    gstType: "";
    placeOfSupply: "";
    website: "";
    // dateCreated: '2023-03-08T05:04:38.000Z';
    dateCreated?="";
    createdBy: 1
  
    }
  
  
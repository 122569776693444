export class invoice
{
  invoiceId: number;
  cargoId: number;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceType: string;
  payingPartyId: number;
  payingParty: string;
  payingPartyAddressId: number;
  payingPartyAddress: string;
  currencyId: number;
  currencyCode: string;
  exchangeRateId: number;
  exchangeRate: number;
  jobNumber: string;
  vesselVoyage: string;
  cycle: string;
  cargoType: string;
  freightStatus: string;
  mblMawb: string;
  pol: string;
  finalDestination: string;
  creditDays: string;
  shipperInvoiceDetails: string;
  hblHawb: string;
  flightDetails: string;
  notes1: string;
  invoiceAmount: number;
  taxAmount: number;
  invoiceAmountWords: string;
  invoiceAmountLocalCurrency: number;
  taxAmountLocalCurrency: number;
  serviceTax: number;
  educationCess: number;
  shecess: number;
  nonTaxableAmount: number;
  taxableAmount: number;
  localCurrencyId: number;
  isLocked: boolean;
  lockedBy: number;
  dateLocked: string;
  isSentToParty: boolean;
  sentBy: number;
  sentDate: string;
  createdBy: number;
  dateCreated: string;
  modifiedBy: number;
  dateModified: string;
  deletedBy: number;
  dateDeleted: string;
  isDeleted: boolean;
  invoiceApprovalStatus: string;
  costSheetId: number;
  invoiceTypeGst: string
}
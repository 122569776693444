export class Opportunity
{
  
    opportunityId= 0;
    accountId:0;
    accountName:"";
    isDeleted= true;
     createdBy=0;
    dateCreated="";
    opportunityOwnerId="";
    amount="";
   opportunityName="";
}
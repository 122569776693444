<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <!-- Basic Alerts start -->
      <section id="home-page">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <!-- <h4 class="card-title">Kick start your project 🚀</h4> -->
              </div>
              <div class="card-body pb-1">
                <section class="horizontal-wizard">
                    <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                      <div class="bs-stepper-header">
                        <div class="step" data-target="#account-details">
                          <button class="step-trigger">
                            <span class="bs-stepper-box">1</span>
                            <span class="bs-stepper-label">
                              <span class="bs-stepper-title">Customer Details</span>
                              <!-- <span class="bs-stepper-subtitle">Setup Account Details</span> -->
                            </span>
                          </button>
                        </div>
                        <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                        <div class="step" data-target="#personal-info">
                          <button class="step-trigger">
                            <span class="bs-stepper-box">2</span>
                            <span class="bs-stepper-label">
                              <span class="bs-stepper-title">Cargo Details</span>
                              <!-- <span class="bs-stepper-subtitle">Add Personal Info</span> -->
                            </span>
                          </button>
                        </div>
                        <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                        <div class="step" data-target="#address">
                          <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                              <span class="bs-stepper-title">Address Details</span>
                              <!-- <span class="bs-stepper-subtitle">Add Address</span> -->
                            </span>
                          </button>
                        </div>
                        <div class="line"><i data-feather="chevron-right" class="font-medium-2"></i></div>
                        <div class="step" data-target="#social-links">
                          <button class="step-trigger">
                            <span class="bs-stepper-box">3</span>
                            <span class="bs-stepper-label">
                              <span class="bs-stepper-title">Overview</span>
                              <!-- <span class="bs-stepper-subtitle">Shipper,Consignee,Notify party, forwarder,Agents</span> -->
                            </span>
                          </button>
                        </div>
                      </div>
                      <div class="bs-stepper-content">
                        <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
                          <div id="account-details" class="content">
                            <form #accountDetailsForm="ngForm">
                              <div class="content-header">
                                <h5 class="mb-0">Opportunity Details</h5>
                                <small class="text-muted">Enter Opportunity Details Here.</small>
                              </div>
              
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="username">Customer</label>
                                  <input
                                    [(ngModel)]="TDNameVar"
                                    #TDNameRef="ngModel"
                                    required
                                    type="text"
                                    name="username"
                                    id="username"
                                    class="form-control"
                                    [class.error]="!TDNameRef.valid && accountDetailsForm.submitted"
                                    placeholder="johndoe"
                                  />
                                  <span *ngIf="accountDetailsForm.submitted && TDNameRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="TDNameRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="email">Sales Person</label>
                                  <select class="form-control" formControlName="salespersonId">
                                    <option [ngValue]="null" disabled>Select Sales Person</option>
                                    <option *ngFor="let user of alluser" [ngValue]="user.userId">{{user.userDisplayName}}</option>
                                  </select>
                                  <!-- <input
                                    [(ngModel)]="TDEmailVar"
                                    #TDEmailRef="ngModel"
                                    required
                                    email
                                    type="email"
                                    name="email"
                                    id="email"
                                    class="form-control"
                                    [class.error]="!TDEmailRef.valid && accountDetailsForm.submitted"
                                    placeholder="john.doe@email.com"
                                    aria-label="john.doe"
                                  />
                                  <span *ngIf="accountDetailsForm.submitted" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="!TDEmailRef.valid">Email must be valid!</small>
                                  </span> -->
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group form-password-toggle col-md-6">
                                  <label class="form-label" for="password">Estimated Amount (INR)</label>
                                  <input
                                    type="number"
                                    name="password"
                                    id="password"
                                    class="form-control"
                                    [class.error]="!TDPasswordOnlyRef.valid && accountDetailsForm.submitted"
                                    placeholder="00000.0"
                                    ngModel
                                    #TDPasswordOnlyRef="ngModel"
                                    required
                                    minlength="6"                                 
                                  />
    
                                </div>
                                
                              </div>
              
                              <div class="d-flex justify-content-between">
                                <button
                                  (click)="horizontalWizardStepperPrevious()"
                                  class="btn btn-outline-secondary btn-prev"
                                  disabled
                                  rippleEffect
                                >
                                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button
                                  (click)="horizontalWizardStepperNext(accountDetailsForm)"
                                  type="submit"
                                  class="btn btn-primary btn-next"
                                  rippleEffect
                                >
                                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                              </div>
                            </form>
                          </div>
                          <div id="personal-info" class="content">
                            <form #personalInfoForm="ngForm">
                              <div class="content-header">
                                <h5 class="mb-0">Personal Info</h5>
                                <small>Enter Your Personal Info.</small>
                              </div>
              
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="first-name">First Name</label>
                                  <input
                                    [(ngModel)]="TDFirstNameVar"
                                    #TDFirstNameRef="ngModel"
                                    required
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    class="form-control"
                                    [class.error]="personalInfoForm.submitted && TDFirstNameRef.invalid"
                                    placeholder="John"
                                  />
                                  <span *ngIf="personalInfoForm.submitted && TDFirstNameRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="TDFirstNameRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="last-name">Last Name</label>
                                  <input
                                    [(ngModel)]="TDLastNameVar"
                                    #TDLastNameRef="ngModel"
                                    required
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    class="form-control"
                                    [class.error]="personalInfoForm.submitted && TDLastNameRef.invalid"
                                    placeholder="Doe"
                                  />
                                  <span *ngIf="personalInfoForm.submitted && TDLastNameRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="TDLastNameRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="country">Country</label>
                                  <ng-select [items]="selectBasic" bindLabel="name"> </ng-select>
                                </div>
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="language">Language</label>
                                  <ng-select
                                    [items]="selectMulti"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    [searchable]="false"
                                    bindLabel="name"
                                    name="selectLanguage"
                                    placeholder="Select people"
                                    [(ngModel)]="selectMultiSelected"
                                  >
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                      <span class="ng-value-label"> {{ item.name }}</span>
                                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                    </ng-template>
                                  </ng-select>
                                </div>
                              </div>
              
                              <div class="d-flex justify-content-between">
                                <button
                                  type="button"
                                  (click)="horizontalWizardStepperPrevious()"
                                  class="btn btn-primary btn-prev"
                                  rippleEffect
                                >
                                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button
                                  (click)="horizontalWizardStepperNext(personalInfoForm)"
                                  class="btn btn-primary btn-next"
                                  rippleEffect
                                >
                                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                              </div>
                            </form>
                          </div>
                          <div id="address" class="content">
                            <form #addressForm="ngForm">
                              <div class="content-header">
                                <h5 class="mb-0">Address</h5>
                                <small>Enter Your Address.</small>
                              </div>
              
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="address">Address</label>
                                  <input
                                    [(ngModel)]="addressVar"
                                    #addressRef="ngModel"
                                    required
                                    type="text"
                                    id="address"
                                    name="address"
                                    class="form-control"
                                    [class.error]="addressForm.submitted && addressRef.invalid"
                                    placeholder="98  Borough bridge Road, Birmingham"
                                  />
                                  <span *ngIf="addressForm.submitted && addressRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="addressRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="landmark">Landmark</label>
                                  <input
                                    [(ngModel)]="landmarkVar"
                                    #landmarkRef="ngModel"
                                    required
                                    type="text"
                                    name="landmark"
                                    id="landmark"
                                    class="form-control"
                                    [class.error]="addressForm.submitted && landmarkRef.invalid"
                                    placeholder="Borough bridge"
                                  />
                                  <span *ngIf="addressForm.submitted && landmarkRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="landmarkRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="pincode1">Pincode</label>
                                  <input type="text" id="pincode1" class="form-control" placeholder="658921" />
                                </div>
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="city1">City</label>
                                  <input type="text" id="city1" class="form-control" placeholder="Birmingham" />
                                </div>
                              </div>
              
                              <div class="d-flex justify-content-between">
                                <button
                                  type="button"
                                  (click)="horizontalWizardStepperPrevious()"
                                  class="btn btn-primary btn-prev"
                                  rippleEffect
                                >
                                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button
                                  (click)="horizontalWizardStepperNext(addressForm)"
                                  class="btn btn-primary btn-next"
                                  rippleEffect
                                >
                                  <span class="align-middle d-sm-inline-block d-none">Next</span>
                                  <i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
                                </button>
                              </div>
                            </form>
                          </div>
                          <div id="social-links" class="content">
                            <form #socialLinkForm="ngForm">
                              <div class="content-header">
                                <h5 class="mb-0">Social Links</h5>
                                <small>Enter Your Social Links.</small>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="twitter">Twitter</label>
                                  <input
                                    [(ngModel)]="twitterVar"
                                    #twitterkRef="ngModel"
                                    required
                                    type="text"
                                    id="twitter"
                                    name="twitter"
                                    class="form-control"
                                    [class.error]="socialLinkForm.submitted && twitterkRef.invalid"
                                    placeholder="https://twitter.com/abc"
                                  />
                                  <span *ngIf="socialLinkForm.submitted && twitterkRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="twitterkRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="facebook">Facebook</label>
                                  <input
                                    [(ngModel)]="facebookVar"
                                    #facebookRef="ngModel"
                                    required
                                    type="text"
                                    id="facebook"
                                    name="facebook"
                                    class="form-control"
                                    [class.error]="socialLinkForm.submitted && facebookRef.invalid"
                                    placeholder="https://facebook.com/abc"
                                  />
                                  <span *ngIf="socialLinkForm.submitted && facebookRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="facebookRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="google">Google+</label>
                                  <input
                                    [(ngModel)]="googleVar"
                                    #googleRef="ngModel"
                                    required
                                    type="text"
                                    id="google"
                                    name="google"
                                    class="form-control"
                                    [class.error]="socialLinkForm.submitted && googleRef.invalid"
                                    placeholder="https://plus.google.com/abc"
                                  />
                                  <span *ngIf="socialLinkForm.submitted && googleRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="googleRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label class="form-label" for="linkedin">Linkedin</label>
                                  <input
                                    [(ngModel)]="linkedinVar"
                                    #linkedinRef="ngModel"
                                    required
                                    type="text"
                                    id="linkedin"
                                    name="linkedin"
                                    class="form-control"
                                    [class.error]="socialLinkForm.submitted && linkedinRef.invalid"
                                    placeholder="https://linkedin.com/abc"
                                  />
                                  <span *ngIf="socialLinkForm.submitted && linkedinRef.invalid" class="invalid-form">
                                    <small class="form-text text-danger" *ngIf="linkedinRef.errors.required"
                                      >This field is required!</small
                                    >
                                  </span>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between">
                                <button
                                  type="button"
                                  (click)="horizontalWizardStepperPrevious()"
                                  class="btn btn-primary btn-prev"
                                  rippleEffect
                                >
                                  <i data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></i>
                                  <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                </button>
                                <button class="btn btn-success btn-submit" rippleEffect (click)="onSubmit()">Submit</button>
                              </div>
                            </form>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
            
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Basic Alerts end -->
    </div>
  </div>
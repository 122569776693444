export class SaleQuates{
    salesQuoteId=0;
    salesQuoteNumber="";
    salesQuoteDate="";
    salesQuoteType="";
    salesPersonId=0;
    salesPersonDisplayName="";
    modeoftransport="";
    direction="";
    companyId=0;
    companyDisplayName="";
    contactId=0;
    contactDisplayName="";
    enqReceivedDate="";
    expiryDate="";
    revisionId=1;
    commodity="";
    dateCreated:any;
    dateModified:any;
    salesQuoteStatus:"";
    companyAddressId:0;
    companyAddress:"";
    preparedBy:0;
    preparedByDislayName:"";
    requiredEquipment:"";
    volume:0;
    grossWeight:0;
    isHazardous:true;
    showTax:true;
    termsConditions:"";
    showTotal:true;
    showCarrier:true;
}
export class salesQuotesChargesModel{
  sqchargeId = 0;
  sqid= 0;
  sqdetailRowId = 0;
  payingPartyId = 0;
  chargeItemId = 0;
  incomeExpense = "";
  chargeDescription = "";
  buyingamount=0;
  applyPer = "";
  buyingRate="";
  quantity = 0;
  rate = 0;
  currencyId = 0;
  currencyCode = "";
  exRate = 0;
  taxRateId = 0;
  taxPercent = 0;
  taxAmount = 0;
  totalAmount = 0;
  totalNonTaxableAmount = 0;
  salesQuoteChargeId = 0;

}
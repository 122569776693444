<div class="row">
  
    <div class="col-md-6 col-12">
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary" rippleEffect (click)="openModal(allType)">
            <i data-feather="plus" class="mr-0 mr-sm-1"></i
            ><span class="d-none d-sm-inline-block">Add New Record</span>
        </button>
      </div>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-md-end">
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <label class="d-flex align-items-center"
          >Search<input
            type="search"
            placeholder="Search"
            class="form-control ml-25"
            (keyup)="filterUpdate($event)"
            (search)="filterUpdate($event)"
        /></label>
      </div>
    </div>
  </div>
  <ngx-datatable
  [rows]="kitchenSinkRows"
  [rowHeight]="58"
  class="bootstrap core-bootstrap"
  [limit]="10"
  
  [headerHeight]="40"
  [footerHeight]="50"
  [scrollbarH]="true"
  [selectionType]="SelectionType.checkbox"
  [limit]="basicSelectedOption"
  
  >
    <ngx-datatable-column
      [width]="100"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)"
            id="headerChkbxRef"
          />
          <label class="custom-control-label" for="headerChkbxRef"></label>
        </div>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)"
            id="rowChkbxRef{{ rowIndex }}"
          />
          <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="ID#" prop="locationId" [width]="100"></ngx-datatable-column>
    <ngx-datatable-column name="Location Name" prop="locationShortName" [width]="220">
   
    </ngx-datatable-column>
   
    <ngx-datatable-column name="Location Type" prop="locationType" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Country Name" prop="countryName" [width]="200"></ngx-datatable-column>
    
    <ngx-datatable-column name="Edit" [width]="200" [sortable]="false">
      <ng-template ngx-datatable-cell-template>
        <div class="d-flex align-items-center">
          
            <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
            ><i data-feather="edit" class="text-primary cursor-pointer"></i> </a
          >
          
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Delete" [width]="200" [sortable]="false">
        <ng-template ngx-datatable-cell-template>
          <div class="d-flex align-items-center">
            <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                  ><i data-feather="trash-2" class="mr-50"></i> </a
                >
          </div>
        </ng-template>
      </ngx-datatable-column>
  </ngx-datatable>

  <!--modal-->
  <div id="alltypemodal">
    <ng-template #allType let-modal>
      <div class="modal-header align-items-center">
       <h5 class="modal-title" id="companyProfileLabel">New Routing Details</h5>
       <button #closeModal  type="button" class="close m-0" (click)="modal.dismiss(oceanlinemasterForm)" aria-label="Close">
        <span aria-hidden="true">&times;</span>
       </button>
      </div>
  
      <div class="modal-body">
        
          <form [formGroup]="alltypemasterForm" #myForm="ngForm">
              <div class="row">
                <div class="col-6">
                 
                        <div class="row ">
                          <div class="col-12">
                            <div class="form-group">
                                  <label for="locationShortName" class="">locationShortName</label>
                                  <input 
                                  type="text"
                                  id="locationShortName "
                                  class="form-control form-control-sm"
                                  placeholder="transitTime" 
                                  formControlName="locationShortName"
                                    />
                                  <div *ngIf="alltypemasterForm.controls['locationShortName'].invalid && alltypemasterForm.controls['locationShortName'].touched" class="text-danger mt-1 validation-text">
                                    <div *ngIf="alltypemasterForm.controls['locationShortName'].errors.required">
                                      locationShortName is required.
                                    </div>
                                  </div>
                            </div>
                          </div>
                        
                        </div>
                </div>
         
                  <div class="col-6">
                    <div class="form-group">
                          <label for="countryName " class="">countryName</label>
                          <input 
                          type="text"
                          id="countryName "
                          class="form-control form-control-sm"
                          placeholder="countryName" 
                          formControlName="countryName"
                            />
                          <div *ngIf="alltypemasterForm.controls['countryName'].invalid && alltypemasterForm.controls['countryName'].touched" class="text-danger mt-1 validation-text">
                            <div *ngIf="alltypemasterForm.controls['countryName'].errors.required">
                              countryName is required.
                            </div>
                          </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="lineCallSign " class="">locationType</label>
                    
                      <input 
                          type="text"
                          id="locationType "
                          class="form-control form-control-sm"
                          placeholder="locationType" 
                          formControlName="locationType"
                            />
                        <div *ngIf="alltypemasterForm.controls['locationType'].invalid && alltypemasterForm.controls['locationType'].touched" class="text-danger mt-1 validation-text">
                          <div *ngIf="alltypemasterForm.controls['locationType'].errors.required">
                            locationType is required.
                          </div>
                       </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="locationCode " class="">locationCode</label>
                    
                      <input 
                          type="text"
                          id="locationCode "
                          class="form-control form-control-sm"
                          placeholder="locationCode" 
                          formControlName="locationCode"
                            />
                        <div *ngIf="alltypemasterForm.controls['locationCode'].invalid && alltypemasterForm.controls['locationCode'].touched" class="text-danger mt-1 validation-text">
                          <div *ngIf="alltypemasterForm.controls['locationCode'].errors.required">
                            locationCode is required.
                          </div>
                       </div>
                    </div>
                  </div>
              
                  <input type="text" formControlName="dateCreated" hidden>
                  <input type="text" formControlName="dateModified" hidden>
                </div>
              <div class="row">
        
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success" (click)="onSubmit(alltypemasterForm)"  rippleEffect>Save Data</button>
                  
                </div>
              </div>
   
      </form>
  
      </div>
  
      
     
  </ng-template>
  </div>
  <!--modal-->
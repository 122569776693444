<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
     <!-- content-header component -->
     <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->
 
     <!-- Kitchen Sink Datatables -->
     <section id="ngx-datatable-kitchen-sink">
       <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
         <h4 class="card-title">Air Import list</h4>
         <div class="row">
           
           <div class="col-md-6 col-12">
             <div class="d-flex justify-content-between align-items-center m-1">
               <label class="d-flex align-items-center"
                 >Show
                 <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                   <option value="10">10</option>
                   <option value="25">25</option>
                   <option value="50">50</option>
                   <option value="100">100</option>
                 </select>
                 entries</label
               >
             </div>
           </div>
           <div class="col-md-6 col-12 d-flex justify-content-md-end">
             <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
               <label class="d-flex align-items-center"
                 >Search<input
                   type="search"
                   placeholder="Search"
                   class="form-control ml-25"
                   (keyup)="filterUpdate($event)"
                   (search)="filterUpdate($event)"
               /></label>
             </div>
           </div>
         </div>
         <ngx-datatable
           [rows]="kitchenSinkRows"
           [rowHeight]="58"
           class="bootstrap core-bootstrap"
           [limit]="10"
           [columnMode]="ColumnMode.force"
           [headerHeight]="40"
           [footerHeight]="50"
           [scrollbarH]="true"
           [selectionType]="SelectionType.checkbox"
           [limit]="basicSelectedOption"   
           (select)="onSelect($event)"
         >
           <ngx-datatable-column
             [width]="50"
             [sortable]="false"
             [canAutoResize]="false"
             [draggable]="false"
             [resizeable]="false"
           >
             <ng-template
               ngx-datatable-header-template
               let-value="value"
               let-allRowsSelected="allRowsSelected"
               let-selectFn="selectFn"
             >
               <div class="custom-control custom-checkbox">
                 <input
                   type="checkbox"
                   class="custom-control-input"
                   [checked]="allRowsSelected"
                   (change)="onCheckboxChangeFn($event)"
                   id="rowChkbxRef{{ rowIndex }}"
                 />
                 <label class="custom-control-label" for="rowChkbxRef"></label>
               </div>
             </ng-template>
             <ng-template
               ngx-datatable-cell-template
               let-rowIndex="rowIndex"
               let-value="value"
               let-isSelected="isSelected"
               let-onCheckboxChangeFn="onCheckboxChangeFn"
             >
             
               <div class="custom-control custom-checkbox">
                 <input
                   type="checkbox"
                   class="custom-control-input"
                   [checked]="isSelected"
                   (change)="onCheckboxChangeFn($event)"
                   id="rowChkbxRef{{ rowIndex }}"
                 />
                 <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                 
               </div>
             </ng-template>
           </ngx-datatable-column>
           <!-- <a *ngFor="let company of companies"> -->
            <ngx-datatable-column name="Job No." prop="jobNo" [width]="150"> 
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
          <div class="d-flex align-items-center">                  
            <div class="cell-line-height">
              <a  routerLink="/aedetails/{{row.cargoId}}" class="font-medium-1 d-block"
                ><span class="font-weight-bold">{{ name }}</span></a>
            </div>
          </div>
        </ng-template>

           </ngx-datatable-column>
           <!-- <ngx-datatable-column name="Job Type" prop="jobType" [width]="150"> 
           </ngx-datatable-column> -->
          <!-- <a *ngFor="let company of companies"> -->
           <ngx-datatable-column name="Consignee" prop="consignee" [width]="280"> 
           </ngx-datatable-column> 
           <ngx-datatable-column name="Shipper" prop="shipper" [width]="230"></ngx-datatable-column>
             <!-- <ng-template let-column="column" ngx-datatable-header-template>{{value | date:'dd-MM-yyyy'}}
              <div>Enquiry Received Date</div>
           </ng-template>  -->
       
             <ngx-datatable-column name="POL" prop="pol" [width]="230">
             </ngx-datatable-column>  
             <ngx-datatable-column name="POD" prop="pod" [width]="230">
               <!-- {{company.companyApprovalStatus}} -->
             </ngx-datatable-column>  
             <ngx-datatable-column name="ETA" prop="eta" [width]="150">
               <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}
               </ng-template>
             </ngx-datatable-column> 
             <ngx-datatable-column name="ETD" prop="etd" [width]="150">
               <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}
               </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Date Created" prop="dateCreated" [width]="180"> 
               <ng-template let-value="value" ngx-datatable-cell-template>{{value | date:'dd-MM-yyyy'}}
              </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Customer Reference" prop="customerReference" [width]="180"> 
             </ngx-datatable-column>
             
             <ngx-datatable-column name="PO/Invoice" prop="po/Invoice" [width]="180"> 
             </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false" prop="id">
             <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
               <button class="btn btn-primary ml-1" rippleEffect>
                 <span class="d-none d-sm-inline-block" (click)="populateForm(row,modalXL,'edit')">Edit</span>
                </button>
             
                <!-- <button class="btn btn-danger ml-1" (click)="onDelete(row.companyId)">
                 <span class="d-none d-sm-inline-block">Delete</span>
                </button> -->
              </ng-template>
              </ngx-datatable-column> 
         <!-- </a> -->
         </ngx-datatable>
       
       </core-card-snippet>
     </section>
     <!--/ Kitchen Sink Datatables -->
 
   </div>
   
 </div>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
      <div class="row" id="table-head">
        <div class="col-12">
          <div class="card">
            <!-- <div class="card-header"><h4 class="card-title">Table head options</h4></div>
            <div class="card-body">
              <p class="card-text">
                Similar to tables and dark tables, use the modifier classes
                <code class="highlighter-rouge">.thead-dark</code> to make
                <code class="highlighter-rouge">&lt;thead&gt;</code> s appear dark.
              </p>
            </div> -->
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th>Job Number</th>
                    <th>Shipper</th>
                    <th>Consignee</th>
                    <th>POD</th>
                    <th>POL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src="assets/images/icons/angular.svg"
                        class="mr-75"
                        height="20"
                        width="20"
                        alt="Angular"
                      /><span class="font-weight-bold">Angular Project</span>
                    </td>
                    <td>Peter Charls</td>
                    <td>
                      <div class="avatar-group">
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Lilian Nenez"
                        >
                          <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                      </div>
                    </td>
                    <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                    <td class="overflow-hidden">
                      <div ngbDropdown container="body">
                        <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                          <i data-feather="more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu>
                          <a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                          ><a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                        class="font-weight-bold"
                        >React Project</span
                      >
                    </td>
                    <td>Ronald Frest</td>
                    <td>
                      <div class="avatar-group">
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Lilian Nenez"
                        >
                          <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                      </div>
                    </td>
                    <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                    <td class="overflow-hidden">
                      <div ngbDropdown container="body">
                        <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                          <i data-feather="more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu>
                          <a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                          ><a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                        class="font-weight-bold"
                        >Vuejs Project</span
                      >
                    </td>
                    <td>Jack Obes</td>
                    <td>
                      <div class="avatar-group">
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Lilian Nenez"
                        >
                          <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                      </div>
                    </td>
                    <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                    <td class="overflow-hidden">
                      <div ngbDropdown container="body">
                        <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                          <i data-feather="more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu>
                          <a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                          ><a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="assets/images/icons/bootstrap.svg"
                        class="mr-75"
                        height="20"
                        width="20"
                        alt="Bootstrap"
                      /><span class="font-weight-bold">Bootstrap Project</span>
                    </td>
                    <td>Jerry Milton</td>
                    <td>
                      <div class="avatar-group">
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Lilian Nenez"
                        >
                          <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-popup="tooltip-custom"
                          placement="top"
                          container="body"
                          title=""
                          class="avatar pull-up my-0"
                          ngbTooltip="Alberto Glotzbach"
                        >
                          <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                        </div>
                      </div>
                    </td>
                    <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                    <td class="overflow-hidden">
                      <div ngbDropdown container="body">
                        <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                          <i data-feather="more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu>
                          <a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                          ><a ngbDropdownItem href="javascript:void(0);"
                            ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>







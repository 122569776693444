export class  Cargo{
  cargoId = 0;
  cargoNumber = "";
  jobNo = "";
  masterNo = "";
  houseNo = "";
  modeOfTransport = "";
  transportDirection = "";
  isConsolidation = false;
  incoTermId =0;
  typeOfMoveId =0;
  pickupAddressId = null;
  deliveryAddressId = null;
  opportunityId =0;
  customerReference = "";
  polid =0;
  pol ="";
  podid =0;
  pod ="";
  etd ="";
  eta ="";
  shipperId =0;
  shipperAddressId =0;
  shipper ="";
  consigneeId =0;
  consigneeAddressId =0;
  consignee ="";
  notifyParty1Id =0;
  notifyParty1AddressId =0;
  notifyParty1 ="";
  notifyParty2Id =null;
  notifyParty2AddressId =null;
  forwarderId =0;
  forwardedAddressId =0;
  originAgentId = null;
  originAgentAddressId =null;
  destinationAgentId= null;
  destinationAgentAddressId =null;
  notes ="";
  createdBy =0;
  dateCreated ="";
  modifiedBy =0;
  dateModified ="";
  deletedBy=null;
  dateDeleted ="";
  isDeleted =true;
  customerId =0;
  cargoApprovalStatus =null;
  cargoSopapprovalStatus =null;
  officeId =0;
  freightStatus =null;
  paymentTerms =null;
  invoicingParty =null;
  jobType =0;
  isHblnoautogenerate =null;
  salesQuoteId =null;
  isLocked =null;
  lockedBy =null;
  lockedDate ="";
  hblTerm =null;
  mblTerm =null;
  hblStatus =null;
  mblStatus =null;
  freeDays =null;
  por =null;
  isGstJob =null;
  cargoContainers =[];
  cargoPackages =[];
  cargoStatuses =[];
  cargoTasks =[];

}
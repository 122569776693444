
<div class="row">
    <!-- Modal -->
    <ng-template #modalForm let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New Ocean Export Cargo</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
          <label>Email: </label>
          <div class="form-group">
            <input type="text" placeholder="Email Address" class="form-control" />
          </div>
          <label>Password: </label>
          <div class="form-group">
            <input type="password" placeholder="Password" class="form-control" />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
          Login
        </button>
      </div>
    </ng-template>
    <!-- / Modal -->

    <div class="col-12 mb-50">
      <button type="button" class="btn btn-primary ml-1" rippleEffect (click)="modalOpenForm(modalForm)" >
        <i data-feather="plus" class="mr-0 mr-sm-1"></i
        ><span class="d-none d-sm-inline-block">New Cargo</span>
      </button>
    </div>
    <div class="col-md-6 col-12">
      <div class="d-flex justify-content-between align-items-center m-1">
        <label class="d-flex align-items-center"
          >Show
          <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries</label
        >
      </div>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-md-end">
      <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
        <label class="d-flex align-items-center"
          >Search<input
            type="search"
            placeholder="Search"
            class="form-control ml-25"
            (keyup)="filterUpdate($event)"
            (search)="filterUpdate($event)"
        /></label>
      </div>
    </div>
  </div>
  <ngx-datatable
    [rows]="kitchenSinkRows"
    [rowHeight]="58"
    class="bootstrap core-bootstrap"
    [limit]="10"
    [columnMode]="ColumnMode.force"
    [headerHeight]="40"
    [footerHeight]="50"
    [scrollbarH]="true"
    [selectionType]="SelectionType.checkbox"
    [limit]="basicSelectedOption"
    (activate)="onActivate($event)"
    (select)="onSelect($event)"
  >
    <ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)"
            id="headerChkbxRef"
          />
          <label class="custom-control-label" for="headerChkbxRef"></label>
        </div>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)"
            id="rowChkbxRef{{ rowIndex }}"
          />
          <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Job No" prop="jobNo" [width]="280">
      <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
        <div class="d-flex align-items-center">
          <ng-template #customAvatar>
            <div
              class="avatar mr-1 ml-0"
              [ngClass]="{
                'bg-light-primary': row.status == '1',
                'bg-light-success': row.status == '2',
                'bg-light-danger': row.status == '3',
                'bg-light-warning': row.status == '4',
                'bg-light-info': row.status == '5'
              }"
            >
              <div class="avatar-content">{{ name | initials }}</div>
            </div>
          </ng-template>
          <div class="cell-line-height">
            <p class="font-medium-1 font-weight-bold line-height-1 mb-25">
              {{ name }}
            </p>
            <span class="text-muted font-small-2"> {{ row.jobNo }}</span>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Shipper" prop="shipper" [width]="250"></ngx-datatable-column>
    <ngx-datatable-column name="Consignee" prop="consignee" [width]="120"></ngx-datatable-column>
    <ngx-datatable-column name="POL" prop="pol" [width]="120"></ngx-datatable-column>
    <ngx-datatable-column name="POD" prop="pod" [width]="120">
    </ngx-datatable-column>
    <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
      <ng-template ngx-datatable-cell-template>
        <div class="d-flex align-items-center">
          <div ngbDropdown container="body">
            <a
              ngbDropdownToggle
              href="javascript:void(0);"
              class="hide-arrow"
              id="dropdownBrowserState"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
              <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                ><i data-feather="file-text" class="mr-50"></i> Details</a
              >
              <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                ><i data-feather="archive" class="mr-50"></i> Archive</a
              >
              <a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
                ><i data-feather="trash-2" class="mr-50"></i> Delete</a
              >
            </div>
          </div>
  
          <i data-feather="edit" class="text-primary cursor-pointer"></i>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
    

export class OceanExport
{
     cargoId= 0;
     cargoNumber= "";
     jobNo= "";
     masterNo= "";
     houseNo= "";
     modeOfTransport= "";
    transportDirection= "";
    isConsolidation= true;
    incoTermId= 0;
    typeOfMoveId= 0;
     pickupAddressId= 0;
     deliveryAddressId= 0;
     opportunityId= 0;
     customerReference= "";
    polid= 0;
    pol= "";
    podid= 0;
    pod= "";
    etd= "";
    eta= "";
    shipperId= 0;
    shipperAddressId= 0;
    shipper= "";
    consigneeId= 0;
    consigneeAddressId= 0;
    consignee= "";
    notifyParty1Id= 0;
    notifyParty1AddressId= 0;
    notifyParty1= "";
    notifyParty2Id= 0;
    notifyParty2AddressId= 0;
    forwarderId= 0;
    forwardedAddressId= 0;
    originAgentId= 0;
    originAgentAddressId= 0;
    destinationAgentId= 0;
    destinationAgentAddressId= 0;
     notes= "";
    createdBy= 0;
    dateCreated= "";
    modifiedBy= 0;
    dateModified= "";
    deletedBy= 0;
    dateDeleted= "";
    isDeleted= true;
    customerId= 0;
     cargoApprovalStatus= "";
     cargoSopapprovalStatus= "";
     officeId= 0;
    freightStatus= "";
     paymentTerms= "";
     invoicingParty= "";
    jobType= 0;
     isHblnoautogenerate= true;
     salesQuoteId= 0;
     isLocked= true;
     lockedBy= 0;
     lockedDate= "";
    hblTerm= "";
    mblTerm= "";
     hblStatus= "";
     mblStatus= "";
     freeDays= "";
     por= "";
     isGstJob= true;
  
  }
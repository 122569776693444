

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="text-center text-primary ">
    <!-- <div class="spinner-border btn-block " id="loading" role="status" style="width: 4rem; height: 4rem" [fullScreen] = "fullScreen" *ngIf="showloader">
          <span class="sr-only">Loading...</span>
    </div> -->
   
  </div>
      <!-- content-header component -->
      <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header>    -->
      <!-- Kitchen Sink Datatables -->
      <section id="ngx-datatable-kitchen-sink">
        <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center"
                  >Show
                  <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
               
                  entries</label
                >
              </div>
            </div>
            <div class="col d-flex justify-content-md-end">
              <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                <label class="d-flex align-items-center"
                  >Search<input
                    type="search"
                    placeholder="Search"
                    class="form-control ml-25"
                    (keyup)="filterUpdate($event)"
                    (search)="filterUpdate($event)"
                /></label>
                <!-- <button class="btn btn-primary ml-2" routerLink="/company"><i data-feather="plus" class="mr-0 mr-sm-1"></i>New Company</button> -->
                <button class="btn btn-gradient-primary ml-2" routerLink="/company"><i data-feather="plus" class="mr-0 mr-sm-1"></i>New Company</button>
              </div>
            </div>
          </div>
          <ngx-datatable
            [rows]="kitchenSinkRows"
            [rowHeight]="58"
            class="bootstrap core-bootstrap"
            class="bootstrap core-bootstrap"
            [limit]="10"
            [columnMode]="ColumnMode.force"
            [headerHeight]="40"
            [footerHeight]="50"
            [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"   
            (select)="onSelect($event)"
          >
            <ngx-datatable-column
              [width]="50"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)"
                    id="headerChkbxRef"
                  />
                  <label class="custom-control-label" for="headerChkbxRef"></label>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
              
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="isSelected"
                    (change)="onCheckboxChangeFn($event)"
                    id="rowChkbxRef{{ rowIndex }}"
                  />
                  <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                  
                </div>
              </ng-template>
            </ngx-datatable-column>
           <!-- <a *ngFor="let company of companies"> -->
            <ngx-datatable-column name="Company Name" prop="companyName" [width]="150"> 
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">                  
                  <div class="cell-line-height">
                    <a  routerLink="/company/{{row.companyId}}" class="font-medium-1 d-block"
                      ><span class="font-weight-bold">{{ name }}</span></a>
                  </div>
                </div>
              </ng-template>
            

            </ngx-datatable-column>
            <ngx-datatable-column name="Company Type" prop="companyType" [width]="200">
              <!-- {{company.companyTypeName}} -->
            </ngx-datatable-column>
            <ngx-datatable-column name="Pan Number" prop="pannumber" [width]="200">
              <!-- {{company.pannumber}} -->
            </ngx-datatable-column>
            <ngx-datatable-column name="GST Type" prop="gstregType" [width]="150">
              
            </ngx-datatable-column>   
            <ngx-datatable-column name="Payment Term" prop="paymentTerm" [width]="150">
              
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="Date Created" prop="dateCreated" [width]="150">
              <ng-template let-value="value" ngx-datatable-cell-template>{{value |
                date:'dd-MM-yyyy'}}</ng-template>
             {{company.companyTypeName}} -->
            <!-- </ngx-datatable-column> -->
      
            <ngx-datatable-column name="Date Created" prop="dateCreated" [width]="200">

              <ng-template let-value="value" ngx-datatable-cell-template>{{value |
                  date:'dd-MM-yyyy HH:MM'}}</ng-template>
              <!-- {{formatdate | date:'short'}} -->
          </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false" prop="id">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button class="btn btn-gradient-primary ml-1" rippleEffect>
                  <span class="d-none d-sm-inline-block" routerLink="/company/{{row.companyId}}">Edit</span>
                 </button>
              
                 <button class="btn btn-gradient-danger ml-1" >
                  <span class="d-none d-sm-inline-block" (click)="onDelete(row.companyId)" [swal]="SweetAlertConfirmColor">Delete</span>
                 </button>
               </ng-template>
               </ngx-datatable-column>
          <!-- </a> -->
          </ngx-datatable>
        
        </core-card-snippet>
      </section>
      <!--/ Kitchen Sink Datatables -->
  
    </div>
</div>
<div class="content-wrapper container-xxl p-0">
  <div class="card container">
    <div class="cardbody pl-2 pr-2 pt-2">
 
        <h2>pricing</h2>
        <!-- <app-pricing-sales-quotes-list></app-pricing-sales-quotes-list> -->
        <!-- <app-sales-quote-form></app-sales-quote-form> -->
        
        <router-outlet></router-outlet>
  </div>
  
  </div>
  
  
  
  
  
  
  
  
  
  
  
<div class="content-wrapper container-xxl p-0">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/ocean-export">Ocean Export List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Ocean Export </li>
        </ol>
    </nav>
    <h3 class="ml-1 mt-2">Export Information</h3>





    <div class="card mt-1 container">
        <div class="cardbody ml-3 mt-1">
            <form class="form" [formGroup]="CompanyForm" >
               
                <h6 class="mt-2">Opportunity Details
                </h6>
                <!-- <hr> -->
                <div class="row mr-1 mt-2">
                    <div class="form-group">
                        <input type="text"  class="form-control" 
                            name="dateCreated" formControlName="dateCreated" hidden />

                    </div>

                    <div class="form-group">
                        <input type="number" id="cargoId " class="form-control" placeholder="Cargo ID"
                            name="cargoId" formControlName="cargoId" hidden />

                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="customer ">Customer</label>
                            <ng-select [items]="GetCustomer"
                                bindLabel="companyName"
                                bindValue="companyId"
                                formControlName="customerId" 
                                placeholder="Customer"
                                (change)="onCustomer($event, CompanyForm)">
                            </ng-select>
                            <div *ngIf="CompanyForm.controls['customerId'].invalid  && CompanyForm.controls['customerId'].touched"
                                    class="text-danger">
                                <div *ngIf="CompanyForm.controls['customerId'].errors.required">
                                    Customer is required.
                                </div>
                            </div> 
                        </div>
                        <input type="text" formControlName="cust" hidden/>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="opportunity ">Opportunity</label>
                            <ng-select placeholder="Opportunity">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="salesPerson ">Sales Person</label>
                            <ng-select [items]="GetSalesPerson"
                                bindLabel="userDisplayName"
                                bindValue="userId"
                                formControlName="opportunityOwnerId" 
                                placeholder="Sales Person"
                                (change)="onSalesPerson($event, CompanyForm)">
                            </ng-select>
                            <div *ngIf="CompanyForm.controls['opportunityOwnerId'].invalid  && CompanyForm.controls['opportunityOwnerId'].touched"
                                    class="text-danger">
                                <div *ngIf="CompanyForm.controls['opportunityOwnerId'].errors.required">
                                    Sales Person is required.
                                </div>
                            </div> 
                        </div>
                        <input type="text" formControlName="sales" hidden/>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="estimatedAmount ">Estimated Amount</label>
                            <input type="text" id="estimatedAmount" class="form-control" placeholder="Estimated Amount"
                                name="estimatedAmount" formControlName="amount"  />
                                <div *ngIf="CompanyForm.controls['amount'].invalid  && CompanyForm.controls['amount'].touched"
                                    class="text-danger">
                                <div *ngIf="CompanyForm.controls['amount'].errors.required">
                                    Estimated Amount is required.
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
               <hr>
                <h6 class="mt-2">Cargo Details
                </h6>
                <!-- <hr> -->
                <div class="row mr-1 mt-2">
                    <div class="col-3">
                        <div class="form-group">

                            <label for="jobNo ">Job No</label>
                            <input type="text" id="jobNo" class="form-control" placeholder="Job No"
                                name="jobNo" formControlName="jobNo" />  
                        </div>                     
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="jobType ">Job Type</label>
                            <ng-select
                                    [items]="GetJobType"
                                    bindLabel="jobtypeName"
                                    bindValue="jobtypeId"
                                    formControlName="jobType" 
                                    placeholder="Job Type"
                                    (change)="onjobType($event, CompanyForm)">
                            </ng-select>
                            <div *ngIf="CompanyForm.controls['jobType'].invalid  && CompanyForm.controls['jobType'].touched"
                                    class="text-danger">
                                <div *ngIf="CompanyForm.controls['jobType'].errors.required">
                                    Job Type is required.
                                </div>
                            </div> 
                        </div>
                        <input type="text" formControlName="job" hidden/>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="modeOfTransport">Mode Of Transport</label>

                            <!-- <input type="text" name="modeOfTransport"  class="form-control"  id="modeOfTransport" readonly value="Ocean"    formControlName="modeOfTransport"> -->
                            <ng-select  
                            formControlName="modeOfTransport"  
                                placeholder="Mode Of Transport" 
                                (change)="OnmodeOfTransport($event, CompanyForm)"
                              >
                                <ng-option *ngFor="let item of items" [value]="item.Name">{{item.Name}}</ng-option>
                                <!-- <ng-option value="Ocean">Ocean</ng-option>
                                <ng-option value="Air">Air</ng-option> -->
                             
                            </ng-select>
                            <!--  [(ngModel)]="selectedmodeOfTransport" -->
                        </div>
                        <input type="text" formControlName="mode" hidden/>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="transportDirection">Direction</label>
                            <ng-select   formControlName="transportDirection" placeholder="Direction">
                                <ng-option *ngFor="let item of directions" [value]="item.Name">{{item.Name}}</ng-option>

                            </ng-select>
                            <!-- [(ngModel)]="selectedDirections" -->
                        </div>
                    </div>
                </div> 
                <div class="row mr-1 mt-2">
                    <div class="col-3">
                        <div class="form-group">
                            <label for="isConsolidation "  >Cargo Type</label>
                            <ng-select placeholder="CargoType"  formControlName="isConsolidation" >
                                <ng-option *ngFor="let item of cargoType"  [value]="item.id"  >{{item.Name}}</ng-option>
                            </ng-select>
                            <div *ngIf="CompanyForm.controls['isConsolidation'].invalid  && CompanyForm.controls['isConsolidation'].touched"
                                    class="text-danger">
                                <div *ngIf="CompanyForm.controls['isConsolidation'].errors.required">
                                    Cargo Type is required.
                                </div>
                            </div> 
                        </div>                     
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="typeOfMove ">Type Of Move</label>
                            <ng-select
                                    [items]="GetTypeOfMove"
                                    bindLabel="typeOfMove"
                                    bindValue="typeOfMoveId"
                                    formControlName="typeOfMoveId" 
                                    placeholder="Type Of Move"
                                    (change)="ontypeOfMove($event, CompanyForm)">
                            </ng-select>
                        </div>
                        <input type="text" formControlName="move" hidden/>

                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="etd ">ETD</label>
                           <input type="date" name="etd" id="etd" class="form-control" placeholder="ETD" formControlName="etd">
                           <div *ngIf="CompanyForm.controls['etd'].invalid  && CompanyForm.controls['etd'].touched"
                           class="text-danger">
                       <div *ngIf="CompanyForm.controls['etd'].errors.required">
                          ETD is required.
                       </div>
                   </div> 
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="eta ">ETA</label>
                            <input type="date" name="eta" id="eta" class="form-control" placeholder="ETA" formControlName="eta">
                            <div *ngIf="CompanyForm.controls['eta'].invalid  && CompanyForm.controls['eta'].touched"
                            class="text-danger">
                        <div *ngIf="CompanyForm.controls['eta'].errors.required">
                           ETA is required.
                        </div>
                    </div> 
                        </div>
                    </div>
                   
                </div> 
                <div class="row mr-1 mt-2">
                    <div class="col-3">
                        <div class="form-group">
                            <label for="pol" >POL</label>
                            <ng-select
                                [items]="GetPOL"
                                bindLabel="locationShortName"
                                bindValue="locationId"
                                formControlName="polid" 
                                placeholder="POL"
                                (change)="onpol($event, CompanyForm)">
                            </ng-select>
                        </div>  
                        <input type="text" formControlName="pol" hidden/>
                   
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="pod">POD</label>
                            <ng-select
                                [items]="GetPOD"
                                bindLabel="locationShortName"
                                bindValue="locationId"
                                formControlName="podid" 
                                placeholder="POD"
                                (change)="onpod($event, CompanyForm)">
                            </ng-select>
                        </div>
                        <input type="text" formControlName="pod" hidden/>

                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="incoTerm">Inco Term</label>
                            <ng-select
                                [items]="GetIncoTerm"
                                bindLabel="incotermName"
                                bindValue="incoTermId"
                                formControlName="incoTermId" 
                                placeholder="Inco Term"
                                (change)="onIncoTerm($event, CompanyForm)">
                            </ng-select>
                            <div *ngIf="CompanyForm.controls['incoTermId'].invalid  && CompanyForm.controls['incoTermId'].touched"
                            class="text-danger">
                        <div *ngIf="CompanyForm.controls['incoTermId'].errors.required">
                            Inco Term is required.
                        </div>
                    </div> 
                        </div>
                        <input type="text" formControlName="incooterm" hidden/>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <label for="freightStatus ">Freight Status</label>
                            <ng-select  formControlName="freightStatus" placeholder="Freight Status">
                                <ng-option *ngFor="let item of freightStatus" [value]="item.Name">{{item.Name}}</ng-option>
                            </ng-select>
                            <!-- [(ngModel)]="selectedFreightStatus"  -->
                        </div>
                    </div>
                </div> 
                <div class="row mr-1 mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="placeOfReceipt ">Place Of Receipt</label>
                            <input type="text" id="placeOfReceipt" class="form-control" placeholder="Place Of Receipt"
                                name="placeOfReceipt" formControlName="placeOfReceipt"/>  
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="invoice ">PO/Invoice</label>
                            <input type="text" id="invoice" class="form-control" placeholder="PO/Invoice"
                                name="invoice" formControlName="customerReference"/>  
                        </div>
                    </div>
                </div>
                <hr>
                <h6 class="mt-2">Overview</h6>


                <div class="row mr-1 mt-2">

                    <!-- <div class="form-group">
                        <input type="text"  class="form-control" 
                            name="shipper" formControlName="shipper" hidden 
                            (change)="onAddress($event)" 
                            />
                    </div> -->

                    <div class="col-6">
                        <div class="form-group">
                            <label for="shipper">Shipper</label>
                            <ng-select 
                                placeholder="Shipper"
                                [items]="GetAddress"
                                bindLabel="addressTypeNick"
                                bindValue="companyAddressId"
                                formControlName="shipperAddressId" 
                                (change)="onAddress($event, CompanyForm)" 
                                id="aa">
                            <ng-template ng-header-tmp>
                              <div class="d-flex align-items-center ng-op-head" >
                                <div class="col-md-1 text-center p-0">#</div>
                                  <div class="col-md-3 text-center border-left"><div class="p-head">Company Name</div></div>
                                  <div class="col-md-3 text-center border-left"><div class="p-head">Address Type Nick</div></div>
                                  <div class="col-md-6 text-center border-left"><div class="p-head">Address</div></div>
                              </div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <b>{{item.addressTypeNick}}</b>
                            </ng-template>
                            
                            <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                              <div class="d-flex addrdata">
                                <div class="col-md-1 text-center  p-body">{{index+1}}</div>
                                <div class="col-md-3 border-left  p-body pl-1">{{item.companyName}}</div>
                                <div class="col-md-3 border-left  p-body pl-1">{{item.addressTypeNick}}</div>
                                <div class="col-md-6  border-left p-body pl-1">{{item.formattedAddress}}</div>
                              </div>
                          </ng-template>
                            </ng-select>
                            <div *ngIf="CompanyForm.controls['shipperAddressId'].invalid  && CompanyForm.controls['shipperAddressId'].touched"
                            class="text-danger">
                        <div *ngIf="CompanyForm.controls['shipperAddressId'].errors.required">
                            Shipper Address is required.
                        </div>
                    </div> 
                        </div> 
                        <input type="text" formControlName="shipper" hidden/>
                    
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="consignee">Consignee</label>
                            <ng-select 
                            placeholder="Consignee"
                                [items]="GetAddress"
                                bindLabel="addressTypeNick"
                                bindValue="companyAddressId"
                                formControlName="consigneeAddressId"  
                                (change)="onConsignee($event, CompanyForm)" 
                                id="aa">
                                <ng-template ng-header-tmp>
                                    <div class="d-flex align-items-center ng-op-head" >
                                      <div class="col-md-1 text-center p-0">#</div>
                                        <div class="col-md-3 text-center border-left"><div class="p-head">Company Name</div></div>
                                        <div class="col-md-3 text-center border-left"><div class="p-head">Address Type Nick</div></div>
                                        <div class="col-md-6 text-center border-left"><div class="p-head">Address</div></div>
                                    </div>
                                  </ng-template>
                                  <ng-template ng-label-tmp let-item="item">
                                    <b>{{item.addressTypeNick}}</b>
                                  </ng-template>
                                  
                                  <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                                    <div class="d-flex addrdata">
                                      <div class="col-md-1 text-center  p-body">{{index+1}}</div>
                                      <div class="col-md-3 border-left  p-body pl-1">{{item.companyName}}</div>
                                      <div class="col-md-3 border-left  p-body pl-1">{{item.addressTypeNick}}</div>
                                      <div class="col-md-6  border-left p-body pl-1">{{item.formattedAddress}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="CompanyForm.controls['consigneeAddressId'].invalid  && CompanyForm.controls['consigneeAddressId'].touched"
                            class="text-danger">
                        <div *ngIf="CompanyForm.controls['consigneeAddressId'].errors.required">
                            Consignee Address  is required.
                        </div>
                    </div> 
                        </div>
                        <input type="text" formControlName="consignee" hidden/>
                    </div>
                </div>
                <div class="row mr-1 mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="notify">Notify Part 1</label>
                            <ng-select 
                            placeholder="Notify Part 1"
                                [items]="GetAddress"
                                bindLabel="addressTypeNick"
                                bindValue="companyAddressId"
                                formControlName="notifyParty1AddressId"  
                                (change)="onNotifyParty1($event, CompanyForm)" 
                                id="aa">
                                <ng-template ng-header-tmp>
                                    <div class="d-flex align-items-center ng-op-head" >
                                      <div class="col-md-1 text-center p-0">#</div>
                                        <div class="col-md-3 text-center border-left"><div class="p-head">Company Name</div></div>
                                        <div class="col-md-3 text-center border-left"><div class="p-head">Address Type Nick</div></div>
                                        <div class="col-md-6 text-center border-left"><div class="p-head">Address</div></div>
                                    </div>
                                  </ng-template>
                                  <ng-template ng-label-tmp let-item="item">
                                    <b>{{item.addressTypeNick}}</b>
                                  </ng-template>
                                  
                                  <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                                    <div class="d-flex addrdata">
                                      <div class="col-md-1 text-center  p-body">{{index+1}}</div>
                                      <div class="col-md-3 border-left  p-body pl-1">{{item.companyName}}</div>
                                      <div class="col-md-3 border-left  p-body pl-1">{{item.addressTypeNick}}</div>
                                      <div class="col-md-6  border-left p-body pl-1">{{item.formattedAddress}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <input type="text" formControlName="NotifyParty1" hidden/>

                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="notifyPart ">Notify Part 2</label>
                            <ng-select 
                            placeholder="Notify Part 2"
                            [items]="GetAddress"
                            bindLabel="addressTypeNick"
                            bindValue="companyAddressId"
                            formControlName="notifyParty2AddressId"  
                            (change)="onNotifyParty2($event, CompanyForm)" 
                            id="aa">
                            <ng-template ng-header-tmp>
                                <div class="d-flex align-items-center ng-op-head" >
                                  <div class="col-md-1 text-center p-0">#</div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Company Name</div></div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Address Type Nick</div></div>
                                    <div class="col-md-6 text-center border-left"><div class="p-head">Address</div></div>
                                </div>
                              </ng-template>
                              <ng-template ng-label-tmp let-item="item">
                                <b>{{item.addressTypeNick}}</b>
                              </ng-template>
                              
                              <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                                <div class="d-flex addrdata">
                                  <div class="col-md-1 text-center  p-body">{{index+1}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.companyName}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.addressTypeNick}}</div>
                                  <div class="col-md-6  border-left p-body pl-1">{{item.formattedAddress}}</div>
                                </div>
                            </ng-template>
                        </ng-select>
                        </div>
                        <input type="text" formControlName="NotifyParty2" hidden/>

                    </div>
                </div> 

                <div class="row mr-1 mt-2">
                    <div class="col">
                        <div class="form-group">
                            <label for="Forwarder">Forwarder</label>
                            <ng-select 
                            placeholder="Forwarder"
                            [items]="GetAddress"
                            bindLabel="addressTypeNick"
                            bindValue="companyAddressId"
                            formControlName="forwardedAddressId" 
                            (change)="onForwarder($event, CompanyForm)"  
                            id="aa">
                            <ng-template ng-header-tmp>
                                <div class="d-flex align-items-center ng-op-head" >
                                  <div class="col-md-1 text-center p-0">#</div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Company Name</div></div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Address Type Nick</div></div>
                                    <div class="col-md-6 text-center border-left"><div class="p-head">Address</div></div>
                                </div>
                              </ng-template>
                              <ng-template ng-label-tmp let-item="item">
                                <b>{{item.addressTypeNick}}</b>
                              </ng-template>
                              
                              <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                                <div class="d-flex addrdata">
                                  <div class="col-md-1 text-center  p-body">{{index+1}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.companyName}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.addressTypeNick}}</div>
                                  <div class="col-md-6  border-left p-body pl-1">{{item.formattedAddress}}</div>
                                </div>
                            </ng-template>
                        </ng-select>
                        </div>   
                        <input type="text" formControlName="Forwarder" hidden/>                  
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="OriginAgent">Origin Agent</label>
                            <ng-select 
                            placeholder="Origin Agent"
                            [items]="GetAddress"
                            bindLabel="addressTypeNick"
                            bindValue="companyAddressId"
                            formControlName="originAgentAddressId" 
                            (change)="onOriginAgent($event, CompanyForm)"   
                            id="aa">
                            <ng-template ng-header-tmp>
                                <div class="d-flex align-items-center ng-op-head" >
                                  <div class="col-md-1 text-center p-0">#</div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Company Name</div></div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Address Type Nick</div></div>
                                    <div class="col-md-6 text-center border-left"><div class="p-head">Address</div></div>
                                </div>
                              </ng-template>
                              <ng-template ng-label-tmp let-item="item">
                                <b>{{item.addressTypeNick}}</b>
                              </ng-template>
                              
                              <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                                <div class="d-flex addrdata">
                                  <div class="col-md-1 text-center  p-body">{{index+1}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.companyName}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.addressTypeNick}}</div>
                                  <div class="col-md-6  border-left p-body pl-1">{{item.formattedAddress}}</div>
                                </div>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="CompanyForm.controls['originAgentAddressId'].invalid  && CompanyForm.controls['originAgentAddressId'].touched"
                            class="text-danger">
                        <div *ngIf="CompanyForm.controls['originAgentAddressId'].errors.required">
                            Origin Agent Address  is required.
                        </div>
                    </div> 
                        </div>
                        <input type="text" formControlName="OriginAgent" hidden/>                  


                    </div>

                    </div>
                    <div class="row mr-1 mt-2">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="DestinationAgent">Destination Agent</label>
                            <ng-select 
                            placeholder="Destination Agent"
                            [items]="GetAddress"
                            bindLabel="addressTypeNick"
                            bindValue="companyAddressId"
                            formControlName="destinationAgentAddressId"  
                            (change)="onDestinationAgent($event, CompanyForm)"   
                            id="aa">
                            <ng-template ng-header-tmp>
                                <div class="d-flex align-items-center ng-op-head" >
                                  <div class="col-md-1 text-center p-0">#</div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Company Name</div></div>
                                    <div class="col-md-3 text-center border-left"><div class="p-head">Address Type Nick</div></div>
                                    <div class="col-md-6 text-center border-left"><div class="p-head">Address</div></div>
                                </div>
                              </ng-template>
                              <ng-template ng-label-tmp let-item="item">
                                <b>{{item.addressTypeNick}}</b>
                              </ng-template>
                              
                              <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                                <div class="d-flex addrdata">
                                  <div class="col-md-1 text-center  p-body">{{index+1}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.companyName}}</div>
                                  <div class="col-md-3 border-left  p-body pl-1">{{item.addressTypeNick}}</div>
                                  <div class="col-md-6  border-left p-body pl-1">{{item.formattedAddress}}</div>
                                </div>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="CompanyForm.controls['destinationAgentAddressId'].invalid  && CompanyForm.controls['destinationAgentAddressId'].touched"
                            class="text-danger">
                        <div *ngIf="CompanyForm.controls['destinationAgentAddressId'].errors.required">
                            Destination Agent Address  is required.
                        </div>
                    </div> 
                        </div>
                        <input type="text" formControlName="DestinationAgent" hidden/>                  

                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="MBLNo ">MBL No.</label>
                            <input type="text" id="MBLNo" class="form-control" placeholder="MBL No"
                            name="MBLNo" formControlName="mblTerm"  />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="HBLNo ">HBL No.</label>
                            <input type="text" id="HBLNo" class="form-control" placeholder="HBL No"
                                name="HBLNo" formControlName="hblTerm"  />
                        </div>
                    </div>
                </div> 
                <div class="mt-2 mb-2">
                    <button type="submit" rippleEffect class="btn btn-gradient-success mb-1" (click)="onSubmitService(CompanyForm)"  [disabled]="CompanyForm.invalid">Save</button>
                    <!-- {{isSavebtn}} -->
                    <!-- (click)="onSubmitService(CompanyForm)" [disabled]="CompanyForm.invalid" -->
                </div>  
            </form>            
        </div>

    <div class="cardbody ml-2 mt-1" *ngIf="isSavebtn">
        <ul ngbNav #nav="ngbNav" class="nav nav-pills" [activeId]="1" >
          <li [ngbNavItem]="1" class="active" >
            <a ngbNavLink class="nav-item nav-link active" aria-controls="ngb-nav-12-panel" [class.active]="viewMode=='container'" (click)="viewMode='container'" aria-selected="true"> Cargo Container</a>
            <ng-template ngbNavContent>
              <div [ngSwitch]="viewMode">
                  <div *ngSwitchCase="'container'">
                      <app-container *ngIf="isSavebtn" [hero]="cargoId" 
                      [myObj]="rowData"></app-container>                       
                  </div>
              </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <a ngbNavLink class="nav-item" [class.active]="viewMode=='packages'" (click)="viewMode='packages'">Packages</a>
            <ng-template ngbNavContent>

              <div [ngSwitch]="viewMode">
                  <div *ngSwitchCase="'packages'">
                      <app-packages  *ngIf="isSavebtn" [hero]="cargoId"></app-packages>
                  </div>
              </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink class="nav-item" [class.active]="viewMode=='shipment-routing'" (click)="viewMode='shipment-routing'"> Shipments Routing </a>
            <ng-template ngbNavContent>
              <div [ngSwitch]="viewMode">
                  <div *ngSwitchCase="'shipment-routing'">
                   <app-shipment-routing  *ngIf="isSavebtn" [hero]="cargoId"></app-shipment-routing>
                  </div>
              </div>
            </ng-template>
        </li>

        <li [ngbNavItem]="4">
            <a ngbNavLink class="nav-item" [class.active]="viewMode=='documents'" (click)="viewMode='documents'">Documents </a>
            <ng-template ngbNavContent>
              <div [ngSwitch]="viewMode">
                  <div *ngSwitchCase="'documents'">
                   <app-documents  *ngIf="isSavebtn" [hero]="cargoId"></app-documents>
                  </div>
              </div>
            </ng-template>
        </li>

        <li [ngbNavItem]="5">
            <a ngbNavLink class="nav-item" [class.active]="viewMode=='invoice'" (click)="viewMode='invoice'">Invoice </a>
            <ng-template ngbNavContent>
              <div [ngSwitch]="viewMode">
                  <div *ngSwitchCase="'invoice'">
                   <app-invoice  *ngIf="isSavebtn" [hero]="cargoId"></app-invoice>
                   <app-bill  *ngIf="isSavebtn" [hero]="cargoId"></app-bill>
                  </div>
              </div>
            </ng-template>
        </li>

        <li [ngbNavItem]="6">
            <a ngbNavLink class="nav-item" [class.active]="viewMode=='status'" (click)="viewMode='status'">Status History </a>
            <ng-template ngbNavContent>
              <div [ngSwitch]="viewMode">
                  <div *ngSwitchCase="'status'">
                   <app-status  *ngIf="isSavebtn" [hero]="cargoId"></app-status>
                   <app-history  *ngIf="isSavebtn" [hero]="cargoId"></app-history>
                  </div>
              </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="7" >
            <a ngbNavLink class="nav-item" href="#" role="button" id="dropdownMenuLink"  [class.active]="viewMode=='cost-sheet'" (click)="viewMode='cost-sheet'" >Cost Sheet <span class="caret"></span></a>
            <!-- <a ngbNavLink class="nav-item dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [class.active]="viewMode=='cost-sheet'" (click)="viewMode='cost-sheet'">Cost Sheet <span class="caret"></span></a> -->
            <ng-template ngbNavContent >
                <!-- <div class="dropdown-menu " aria-labelledby="dropdownMenuLink" [ngClass]="{ 'show': isDropdownOpen }" (click)="isDropdownOpen">{{isDropdownOpen}}
                    <div class="dropdown-item" href="#" (click)="toggleDropdown();showComponent()"  >Freight {{isDropdownOpen}}</div>
                    <div class="dropdown-item" href="#" (click)="toggleDropdown();showComponent1()">Warehousing {{isDropdownOpen}}</div>
                    <div class="dropdown-item" href="#" (click)="selectOption('option3')">Option 3{{isDropdownOpen}}</div>
                </div> -->
               <div class="card-body" [style.height]="cardBodyHeight">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Create Cost Sheet
                    </button>
                    <ul class="dropdown-menu fade dropdown-menu-end">
                      <li><a class="dropdown-item" (click)="showComponent($event)">Freight</a></li>
                      <li><a class="dropdown-item" (click)="showComponent($event)">Warehousing</a></li>
                      <li><a class="dropdown-item" (click)="showComponent($event)">Custom Clearance</a></li>
                    </ul>
                  </div>
                 <!-- <p>{{message}}</p> -->
                 <div *ngIf="isalert" class="alert alert-success p-2 mt-3" role="alert">
                    {{message}}
                  </div>
                 <!-- <app-cost-sheet  *ngIf="isComponentVisible" [hero]="cargoId" [modeOfTransport]="rowData.modeOfTransport" [transportDirection]="rowData.transportDirection"  [myObj]="rowData"></app-cost-sheet> -->
                  
                    <div class="costsheet" *ngIf="isComponentVisible">
                        <div class="content-wrapper container-xxl p-0">
                            <div class="table-container">
                            <div class="row pt-2">
                                <!-- <button class="btn btn-primary" (click)="addCostSheet()"  [disabled]="isButtonDisabled">Generate Cost Sheet</button> -->
                                <!-- <button class="btn ml-1 btn-gradient-primary" (click)="patch()">Patch value</button>
                                <button class="btn ml-2  btn-gradient-primary" (click)="addRow()">Add Charges</button> -->
                            </div>
                            <div class="pt-2">
                                <form [formGroup]="chargesForm"> 
                                <table class="table table-bordered table-responsive"  >
                                    <thead>
                                    <tr class="text-uppercase">
                                        
                                        <th colspan="3" >Charge</th>
                                        <th colspan="5" >Buying</th>
                                        <th colspan="5">Selling</th>
                                        <th colspan="2"  >Paying Party</th>           
                                        <th>Action</th> 
                                    </tr>
                                    <tr>
                                        <th>Charge_Item</th>
                                        <th>Quantity</th>
                                        <th>Apply Per</th>
                            
                                        <th>Buying Rate</th>
                                        <th>Currency</th>
                                        <th>Tax Percentage</th>
                                        <!-- <th>Buy ExRate</th> -->
                                        <th>Buying Amount</th>
                                        <th>Buy Tax Amount</th>
                            
                                        <th>Selling Rate</th>
                                        <th>Currency</th>
                                        <th>Tax Percentage</th>
                                        <!-- <th>Sell ExRate</th> -->
                                        <th>Selling Amount</th>
                                        <th>Sell Tax Amt</th>
                            
                            
                                        <th>Invoice To</th>
                                        <th>Pay To</th>
                                        <!-- <th>Bill No</th>
                                        <th>Bill Date</th> -->
                            
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                
                                    <tbody formArrayName="Rows">
                                    <tr
                                        *ngFor="
                                            let itemrow of chargesForm.controls.Rows.controls;
                                            let i = index;
                                            let l = last
                                        "
                                        [formGroupName]="i"
                                        >
                                    
                                        <td>
                                          <!-- <input type="number" value="{{companydataurlid||sqID}}" id="sqid{{ i }}" formControlName="cargoId" hidden />-->
                                            <input type="number" value="{{costSheetId||costsheetnumber}}" id="costSheetId{{ i }}" formControlName="costSheetId" hidden />
                                            <input type="number" value="{{chargeId}}" id="chargeId{{ i }}" name="chargeId{{i}}" formControlName="chargeId" hidden /> 
                                          
                            
                                            <ng-select
                                        
                                            [items]="GetChargeItem"
                                            bindLabel="chargeItemName"
                                            bindValue="chargeItemId"
                                            formControlName="chargeItemId" 
                                            placeholder="chargeItemId"
                                            (change)="onChangeChargeMaster($event, chargesForm)"
                                            class="stretchable-dropdown"
                                            >
                                        <ng-template ng-header-tmp>
                                            <div class="d-flex align-items-center ng-op-head" >
                                            <div class="col-md-4 text-center border-left"><div class="p-head">Direction</div></div>
                            
                                                <div class="col-md-8 text-center"><div class="p-head">Charge Description</div></div>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item">
                                            {{item.chargeItemName}}
                                        </ng-template>
                                        
                                        <ng-template ng-option-tmp let-item="item" let-index="index" class="p-0 border-bottom ">
                                            <div class="d-flex addrdata">
                                            <!-- <div class="col-md-1 text-center  p-body">{{index+1}}</div> -->
                                            <div class="col-md-4 border-left  p-body pl-1">{{item.transportationDirection}}</div>
                            
                                            <div class="col-md-8 p-body"><div class="p-head">{{item.chargeItemName}}</div></div>
                                            </div>
                                        </ng-template>
                                        </ng-select>
                                        <input type="text" formControlName="chargeDescription" hidden/>
                            
                                        </td>
                                        <td>
                                            <!-- <input type="text" class="form-control" formControlName="lastname" placeholder="Enter Last">
                                            <div class="text-danger" *ngIf="getFormControls.controls[i].get('lastname')?.touched">
                                            <span *ngIf="getFormControls.controls[i].get('lastname')?.errors?.['required']">Required</span>
                                            </div> -->
                                            <input type="number" 
                                            id="quantity{{i}}" 
                                            formControlName="quantity" 
                                            (change)="onChange(i)"
                                            placeholder="0"
                                            class="form-control form-control-sm"
                                            width="500px"
                                            />
                                        </td>
                                        <td>
                                            <ng-select [items]="GetApplyPer"
                                                    bindLabel="applyPerName"
                                                    bindValue="applyPerName"
                                                    formControlName="applyPer" 
                                                    placeholder="Apply Per"
                                                    >
                                            </ng-select>  
                                        </td>  
                                        <td>
                                            <input
                                            type="number"
                                            class="form-control form-control-sm"
                                            id="BuyingRate"
                                            name="BuyingRate{{ i }}"
                                            placeholder="0"
                                            [width]="200"
                                            formControlName="rate"
                                            (change)="onChange(i)"
                                        />
                                        </td>
                                        <td>
                                            <ng-select [items]="GetCurrency"
                                                    bindLabel="currencyName"
                                                    bindValue="currencyId"
                                                    formControlName="currencyId" 
                                                    placeholder="Currency"
                                                    (change)="onChangeCurrency($event,i)">
                                            </ng-select>   
                                            <input type="text" formControlName="currencyCode" hidden/>

                                            <!-- <input type="text" formControlName="currCode" hidden/> -->
                                        
                                        </td>
                                        <td>
                                            <ng-select [items]="GetTaxPercentage"
                                                    bindLabel="taxName"
                                                    bindValue="effectiveTaxPercentage"
                                                    formControlName="taxPercent" 
                                                    placeholder="Tax"
                                                    (change)="onChange(i)">
                                            </ng-select>              
                                        </td>
                                        <!-- <td>
                                            <input type="text" class="form-control" formControlName="city" placeholder="Enter City">
                                        </td> -->
                                        <td>
                                            <input
                                            type="number"
                                            class="form-control form-control-sm"
                                            id="BuyingAmount"
                                            name="buyingamount{{i}}"
                                            (change)="onChange(i)"
                                            placeholder="0"
                                            formControlName="totalAmount"
                                            readonly          
                                        />
                                        </td>
                                        <td>
                                            <input
                                            type="number"
                                            class="form-control form-control-sm"
                                            id="taxAmount"
                                            name="taxAmount{{i}}" 
                                            placeholder="0"
                                            formControlName="taxAmount"
                                            (change)="onChange(i)"       
                                            readonly         
                                            />
                                        
                                        </td>
                                        <td>
                                            <input
                                            type="number"
                                            class="form-control form-control-sm"
                                            id="sellingingRate"
                                            name="sellingingRate{{ i }}"
                                            placeholder="0"
                                            [width]="200"
                                            formControlName="sellingrate"
                                            (change)="onChange(i)"                 
                                        />         
                                    </td>
                                        <td>
                                            <ng-select [items]="GetCurrency"
                                                    bindLabel="currencyName"
                                                    bindValue="currencyId"
                                                    formControlName="currencyId" 
                                                    placeholder="Currency"
                                                    >
                                            </ng-select> 
                                            <!-- <input type="text" formControlName="currencyCode" hidden/> -->
                                        
                                        </td>
                                        <td>
                                            <ng-select [items]="GetTax"
                                                    bindLabel="taxName"
                                                    bindValue="effectiveTaxPercentage"
                                                    formControlName="selltaxPercent" 
                                                    placeholder="Tax"
                                                    (change)="onChange(i)">
                                            </ng-select>              
                                        </td>
                                        <!-- <td>
                                            <input type="text" class="form-control" formControlName="city" placeholder="Enter City">
                                        </td> -->
                                        <td>
                            
                                            <input
                                            type="number"
                                            class="form-control form-control-sm"
                                            id="selltotalAmount"
                                            name="selltotalAmount{{i}}"
                                            (change)="onChange(i)"
                                            placeholder="0"
                                            formControlName="selltotalAmount"
                                            readonly                           
                                        />
                                        </td>
                                        <td>
                                            <input
                                        width="200px"
                                            type="number"
                                            class="form-control form-control-sm"
                                            id="selltaxAmount"
                                            name="selltaxAmount{{i}}" 
                                            placeholder="0"
                                            formControlName="selltaxAmount"
                                            (change)="onChange(i)"
                                            readonly
                                        />
                                        </td>
                                        <td>
                                            <ng-select [items]="GetInvoiceTo"
                                                    bindLabel="companyName"
                                                    bindValue="companyId"
                                                    formControlName="invoiceTo" 
                                                    placeholder="Invoice To"
                                                    (change)="onInvoiceTo($event, chargesForm)">
                                            </ng-select>              
                                        </td>
                                        <td>
                                            <ng-select [items]="GetPayTo"
                                                            bindLabel="companyName"
                                                            bindValue="companyId"
                                                            formControlName="payingPartyId" 
                                                            placeholder="Pay To"
                                                            (change)="onPayTo($event, chargesForm)">
                                            </ng-select>
                                        </td>
                                        <!-- <td>
                                            <input type="text" class="form-control" formControlName="city" placeholder="Enter City">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" formControlName="state" placeholder="Enter state">
                                        </td> -->
                                        <td>
                                            <button class="btn btn-danger" (click)="remove(i, costSheetId||costsheetnumber,chargesForm.value.Rows[i].chargeId,chargesForm.value.Rows[i].cargoId)">Remove</button>
                                        </td>
                                        </tr>
                                    
                                    
                                    </tbody>
                            
                            
                            
                                    <tfoot>
                                    <tr style="background-color: #f3f2f7;">
                                        <td colspan="6"></td>
                                        <td><input type="text" value="{{totalBuyingAmt}}" [disabled]="true"></td>
                                        <td><input type="text" value="{{totalBuyingAmtTax}}" [disabled]="true"></td>
                                        <td colspan="3"></td>
                                        <td><input type="text" value="{{totalSellingAmt}}" [disabled]="true"></td>
                                        <td><input type="text" value="{{totalSellyingAmtTax}}" [disabled]="true"></td>
                                        <td colspan="3"></td>
                                    
                                    </tr>
                                    <tr><td colspan="15"><a type="button" (click)="addNewRow()" class="btn btn-outline-secondary btn-sm">
                                        <span>Add</span>  Charges
                                      </a></td>
                                    </tr>
                                    <!-- <ng-container>
                                        <tr>
                                        <td colspan="18">
                                            <div class="row">
                                            <div class="col-12 text-start">
                                                <button class="btn btn-success" [disabled]="chargesForm.invalid" (click)="onAddCharges(chargesForm)">Save Bulk Charges</button>
                                            </div>
                                            </div>
                                        </td>
                                        </tr>
                                    </ng-container> -->
                                 
                                    </tfoot>
                                </table>
                                <footer>
                                    <div class="row postion-absolute1 profitrow1 d-flex align-items-center mt-5"> 
                                        <div class="col-md-2"><div class="text-left text-uppecase"><div style="padding-bottom: 3px;font-size: 12px;"><b>Profit Excluding Tax</b></div><div><input type="text" class="text-success profit_input" value="{{profitExcludingTax}}" disabled/></div></div></div>
                                        <div class="col-md-2"><div class="text-left text-uppecase"><div style="padding-bottom: 3px;font-size: 12px;"><b>Profit Including Tax</b></div><div><input type="text" class="text-success profit_input" value="{{profitIncludingTax}}" disabled/></div></div></div>
                                         
                                        <div class="col-md-8 text-right">
                                          <button (click)="onAddCharges(chargesForm,costSheetId||costsheetnumber,cargoId||OEdataurlid)" class="btn btn-success btn-sm">Save</button>
                                      
                                            <b><button id="generatePdfButton" class="btn btn-light ml-1" (click)="generatePDF(chargesForm,costSheetId)">
                                              
                                              Generate <i class="fa fa-file-pdf-o" style="font-size:15px;color:red"></i>
                                            </button></b>
    
                                        </div>
                                        <div class="col-md-12 text-right">
                                        
                                            <div *ngIf="isValidFormSubmitted&&chargesForm.invalid" class="text-danger mt-1 validation-text">Please fill all the details.</div>
                                        </div>
                                      </div>
                                      
                                </footer>
                                </form>
                                <!-- <code>{{ chargesForm.value| json }}</code>  -->
                            </div>
                            </div>
                            </div>
                            
                            
                            
                    </div>
               </div>
          
           
            </ng-template>
        </li>
        </ul>


        <div [ngbNavOutlet]="nav" class="mt-1"></div>


  
      </div>
    </div>

</div>    
    

<div class="content-wrapper container-xxl p-0">
<h4>Invoice</h4>
    <section id="ngx-datatable-kitchen-sink">
        <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
            <!-- <div class="col-6 col-sm-4">
                <button class="btn btn-primary ml-1 mb-2" rippleEffect (click)="modalOpenForm(modalForm)">
                    <i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">New
                        Address</span>
                </button>
            </div> -->
            <!-- <div class="row" mt-4>

                <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-between align-items-center m-1">
                        <label class="d-flex align-items-center">Show
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entries</label>
                    </div>
                </div>
                <div class="col-md-6 col-12 d-flex justify-content-md-end">
                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                        <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                                class="form-control ml-25" (keyup)="filterUpdate($event)"
                                (search)="filterUpdate($event)" /></label>
                    </div>
                </div>
            </div> -->



            <ngx-datatable [rows]="kitchenSinkRows" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="10"
                [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [columnMode]="ColumnMode.force"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (select)="onSelect($event)" >
                <!-- (activate)="onActivate($event)" -->

                <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                        let-selectFn="selectFn">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                            <label class="custom-control-label" for="headerChkbxRef"></label>
                        </div>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                        let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                            <label class="custom-control-label" for="rowChkbxRef{{ rowIndex }}"></label>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <!-- <ngx-datatable-column name="Cargo Pack Name" prop="cargoPackName" [width]="180">
                      <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                        <div (click)="populateForm(row,modalForm)" class="cursor-pointer"><b>{{row.cargoPackName}}</b></div>
                      </ng-template>
                </ngx-datatable-column> -->
                <ngx-datatable-column name="Type" prop="invoiceType" [width]="120">
                </ngx-datatable-column>
                <ngx-datatable-column name="Invoice" prop="invoiceNumber" [width]="180"></ngx-datatable-column>
                
                <ngx-datatable-column name="Invoice Date" prop="invoiceDate" [width]="200">
                    <ng-template let-value="value" ngx-datatable-cell-template>{{value |date:'dd-MM-yyyy HH:MM'}}</ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="invoice Number" prop="invoiceNumber" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column name="paying Party" prop="payingParty" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column name="invoice Amount" prop="invoiceAmount" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column name="currency" prop="currencyCode" [width]="200"></ngx-datatable-column>
                <ngx-datatable-column name="Created By" prop="createdBy" [width]="120"></ngx-datatable-column>
                <ngx-datatable-column name="Date Created" prop="dateCreated" [width]="200">
                    <ng-template let-value="value" ngx-datatable-cell-template>{{value |date:'dd-MM-yyyy HH:MM'}}</ng-template>
                </ngx-datatable-column>



                <!-- <ngx-datatable-column name="Actions" [width]="180" [sortable]="false" prop="id">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <button class="btn btn-gradient-primary ml-1" rippleEffect>
                            <span class="d-none d-sm-inline-block" (click)="populateForm(row,modalForm)">Edit</span>
                        </button>

                        <button class="btn btn-gradient-danger ml-1 me-2" (click)="onDelete(row.companyAddressId)"
                            [swal]="SweetAlertConfirmColor" rippleEffect>
                            <span class="d-none d-sm-inline-block ">Delete</span>
                        </button>
                        <swal #SweetAlertConfirmColor (open)="ConfirmColorOpen()"></swal>
                    </ng-template>
                </ngx-datatable-column> -->
            </ngx-datatable>
        <!-- <div class="col-2">
            <button class="btn btn-gradient-primary" rippleEffect (click)="modalOpenForm(modalForm)">
                <i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">New Container</span>
            </button>
        </div> -->
        </core-card-snippet>
    </section>
</div>
export class Contact


    {
        contactId= 0;
        companyId= 0;
        contactTypeId= 0;
        salutation= "";
        firstName= "";
        middleName= "";
        lastName= "";
        displayName= "";
        email= "";
        mobile= "";
        phone= "";
        department= "";
        reportsTo= 0;
        addressId= 0;
        notes= "";
        createdBy= 0;
        dateCreated= "2023-04-04T04:58:52.853Z";
        modifiedBy= 0;
        dateModified= "2023-04-04T04:58:52.853Z";
        deletedBy= 0;
        dateDeleted= "2023-04-04T04:58:52.853Z";
        isDeleted= true;
        dob= "2023-04-04T04:58:52.853Z";
        
        company?=null;
        // company= {
        //   companyId= 0;
        //  companyName= "";
        //  companyCode= "";
        //  companyTypeId= 0;
        //  companyTypeName= "";
        //  parentCompanyId= 0;
        //  email= "";
        //  website= "";
        //    notes= "";
        //   createdBy= 0;
        //   dateCreated= "2023-04-04T04:58:52.853Z";
        //   modifiedBy= 0;
        //   dateModified="2023-04-04T04:58:52.853Z";
        //   deletedBy= 0;
        //   dateDeleted= "2023-04-04T04:58:52.853Z";
        //   isDeleted= true;
        //   officeId= 0;
        //    companyApprovalStatus= "";
        //    ownerId= 0;
        // cafReceived= true;
        //   cafReceivedDate= "2023-04-04T04:58:52.853Z";
        //   creditDays= "";
        //   mepzCode= "";
        //   pannumber= "";
        //   gsttypeId= 0;
        //   gstin= "";
        //   pan= "";
        //   paymentTermId= 0;
        //   paymentTermLabel= "";
        //   gsttypeName= "";
           companyType?= null;
            // companyTypeId= 0;
            // companyType1= "";
            // isInternal= true;
            // isActive= true
        //   }
        // }
      }
      

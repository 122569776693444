
<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->
  </div>
<!--many dropdown  options -->
  <ul class="nav navbar-nav align-items-center">
    <li ngbDropdown class="nav-item dropdown">
      <div ngbDropdown [placement]="'auto'" class="group dropup">
  
        <a type="button" class="nav-link nav-link-style btn dropdown-toggle" id="navbarNewDropdown" ngbDropdownToggle
          aria-haspopup="true" aria-expanded="false">
          <span [data-feather]="'plus'" [class]="'ficon font-medium-5 feather'"></span>
        </a>
        <div ngbDropdownMenu style="width: 800px; padding: 20px; top: 41px !important;" aria-labelledby="navbarNewDropdown"
          class="dropdown-menu dropdown-menu-center">
          <div>
            <!-- <div ngbDropdownItem> -->
            <div class="row">
              <div class="col">
                <b> Companies </b>
              </div>
              <div class="col">
                <b>Operations</b>
              </div>
              <div class="col">
                <b>Sales</b>
              </div>
              <div class="col">
                <b> Accounts</b>
              </div>
              <div class="col">
                <b> Admin</b>
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col">
                <!-- <button class="btn btn-primary ml-1" rippleEffect (click)="modalOpenForm(modalForm)">
                  <i data-feather="plus" class="mr-0 mr-sm-1" ></i>
                  <span class="d-none d-sm-inline-block" >Add New Company</span>
                </button> -->

                <p  (click)="modalOpenForm(modalForm)">Customer</p>
                <!-- <button type="button"  (click)="modalOpenForm(modalForm)" class="btn btn-outline-primary" rippleEffect>
                 Customer
                </button> -->
                <ng-template #modalForm let-modal>
                  <div class="modal-header">
                   <h5 class="modal-title" id="companyProfileLabel">Add Company</h5>
                   <button type="button" class="close" (click)="modal.dismiss(CompanyForm.reset)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                   </button>
                  </div>
  
                  <div class="modal-body">
                    
                      <form [formGroup]="CompanyForm"  >
                          
                                  <div class="form-group row">
                                  <label for="CompanyName " class="col-sm-4 col-form-label">Company Name :</label>
                                  <div class="col-12">
                                      <input 
                                      type="text"
                                      id="companyName "
                                      class="form-control"
                                      placeholder="Company Name" 
                                      name="companyName"
                                      formControlName="companyName"
                                      />
                                      <span class="text-danger"
                                                *ngIf="(CompanyForm.controls['companyName'].touched || submitted) && CompanyForm.controls['companyName'].errors?.['required']">Company Name is required*
                                      </span>
                                  </div>
                              </div>
                             
                              <div class="form-group row">
                                      <label for="displayName ">Display Company Name :</label>
                                      <div class="col-12">
                                      <input 
                                      type="text"
                                      id="displayName "
                                      class="form-control"
                                      placeholder="Display Company Name" 
                                      name="displayName"
                                      formControlName="displayName"
                                      />
                                      <span class="text-danger"
                                                *ngIf="(CompanyForm.controls['displayName'].touched || submitted) && CompanyForm.controls['displayName'].errors?.['required']">Display Name is required*
                                      </span>
                                    </div>
                              </div>
                            
                        
  
  
                          <div class="row mr-1" >
                              <div class="col">
                                  <div class="form-group">
                                  <label for="companyTypeId ">Company Type Id :</label>
                                  <input 
                                  type="text"
                                  id="companyTypeId "
                                  class="form-control"
                                  placeholder="company Type Id" 
                                  name="companyTypeId"
                                  formControlName="companyTypeId"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['companyTypeId'].touched || submitted) && CompanyForm.controls['companyTypeId'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              </div>
                              <div class="col">
                                  <div class="form-group">
                                  <label for="companyTypeName ">Company Type Name:</label>
                                  <input 
                                  type="text"
                                  id="companyTypeName "
                                  class="form-control"
                                  placeholder="CompanyTypeName" 
                                  name="companyTypeName"
                                  formControlName="companyTypeName"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['companyTypeName'].touched || submitted) && CompanyForm.controls['companyTypeName'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              </div>
                              <div class="col">
                                  <div class="form-group">
                                  <label for="parentCompanyId ">Parent Company Id:</label>
                                  <input 
                                  type="text"
                                  id="parentCompanyId "
                                  class="form-control"
                                  placeholder="Parent Company Id" 
                                  name="parentCompanyId"
                                  formControlName="parentCompanyId"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['parentCompanyId'].touched || submitted) && CompanyForm.controls['parentCompanyId'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              </div>
                          </div>
  
  
  
                          <div class="row mr-1" >
                              <div class="col">
                                  <div class="form-group">
                                  <label for="parentCompanyName ">Parent Company Name  :</label>
                                  <input 
                                  type="text"
                                  id="parentCompanyName "
                                  class="form-control"
                                  placeholder="Parent Company Name" 
                                  name="parentCompanyName"
                                  formControlName="parentCompanyName"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['parentCompanyName'].touched || submitted) && CompanyForm.controls['parentCompanyName'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              </div>
                              <div class="col">
                                  <div class="form-group">
                                  <label for="gstNumber ">gst Number :</label>
                                  <input 
                                  type="text"
                                  id="gstNumber "
                                  class="form-control"
                                  placeholder="gstNumber" 
                                  name="gstNumber"
                                  formControlName="gstNumber"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['gstNumber'].touched || submitted) && CompanyForm.controls['gstNumber'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              </div>
                              <div class="col">
                                  <div class="form-group">
                                  <label for="gstType ">gst Type</label>
                                  <input 
                                  type="text"
                                  id="gstType "
                                  class="form-control"
                                  placeholder="gst Type" 
                                  name="gstType"
                                  formControlName="gstType"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['gstType'].touched || submitted) && CompanyForm.controls['gstType'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              </div>
                          </div>
  
  
  
                          <div class="row mr-1" >
                              <div class="col">
                                  <div class="form-group">
                                  <label for="placeOfSupply ">placeOfSupply</label>
                                  <input 
                                  type="text"
                                  id="placeOfSupply "
                                  class="form-control"
                                  placeholder="place Of Supply" 
                                  name="placeOfSupply"
                                  formControlName="placeOfSupply"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['placeOfSupply'].touched || submitted) && CompanyForm.controls['placeOfSupply'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              </div>
                              <div class="col">
                                  <div class="form-group">
                                  <label for="website ">website  :</label>
                                  <input 
                                  type="text"
                                  id="website "
                                  class="form-control"
                                  placeholder="website " 
                                  name="website"
                                  formControlName="website"
                                  />
                                  <span class="text-danger"
                                  *ngIf="(CompanyForm.controls['website'].touched || submitted) && CompanyForm.controls['website'].errors?.['required']">Display Name is required*
                        </span>
                              </div>
                              
                          
                              </div>
                          </div>
                    
                      <div class="modal-footer">
                          <button #closeModal type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="modal.dismiss()">Close</button>
                          <button type="button" class="btn btn-success" (click)="onSubmitService(CompanyForm)" [disabled]="CompanyForm.invalid"  [RouterLink]="['appointments']" rippleEffect>Save Data</button>
                        
                      </div>
                          
                      </form>
  
                  </div>
  
                  
                 
              </ng-template>
              </div>
              <div class="col" routerLink="/ocean-export">
                Ocean Export
              </div>
              <div class="col">
                Appointment
              </div>
              <div class="col">
                Invoice
              </div>
              <div class="col">
                User
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col">
                Vendor
              </div>
              <div class="col" routerLink="/ocean-import">
                Ocean Import
              </div>
              <div class="col">
                Lead
              </div>
              <div class="col" routerLink="/ocean-import">
                Bill
              </div>
              <div class="col">
                Ocean Port
              </div>
            </div>
  
  
            <div class="row mt-2">
              <div class="col">
                <!-- modal check -->





                <button type="button" (click)="modalOpen(modalBasic)" class="btn btn-outline-primary" rippleEffect>
                  Basic Modal
                </button>
                <ng-template #modalBasic let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel1">Basic Modal</h4>
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" tabindex="0" ngbAutofocus>
                    <h5>Check First Paragraph</h5>
                    <p>
                      Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie. Brownie
                      carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie
                      chocolate cake liquorice.
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
                      Accept
                    </button>
                  </div>
                </ng-template>
                <!-- / Modal -->





              </div>
              <div class="col" routerLink="/air-export">
                Air Export
              </div>
              <div class="col" routerLink="/sales-quotes">
                Sales Quote
              </div>
              <div class="col">
                Payment Receipt
              </div>
              <div class="col">
                Airport
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col">
  
              </div>
              <div class="col" routerLink="/air-import">
                Air Import
              </div>
              <div class="col">
  
              </div>
              <div class="col">
                Vendor Payment
              </div>
              <div class="col">
                New Report
              </div>
            </div>
  
  
            <div class="row mt-2">
              <div class="col">
  
              </div>
              <div class="col">
                HBL
              </div>
              <div class="col">
  
              </div>
              <div class="col">
                Charge Item
              </div>
              <div class="col">
                Ocean Line
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col">
  
              </div>
              <div class="col">
                AWB
              </div>
              <div class="col">
  
              </div>
              <div class="col">
  
              </div>
              <div class="col">
                Air Line
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col">
  
              </div>
              <div class="col">
                Fund Planning
              </div>
              <div class="col">
  
              </div>
              <div class="col">
  
              </div>
              <div class="col">
  
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col">
  
              </div>
              <div class="col">
                Delivery Order
              </div>
              <div class="col">
  
              </div>
              <div class="col">
  
              </div>
              <div class="col">
  
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col">
  
              </div>
              <div class="col">
                Cargo Arrival Notice
              </div>
              <div class="col">
  
              </div>
              <div class="col">
  
              </div>
              <div class="col">
  
              </div>
            </div>
  
          </div>
        </div>
      </div>
      </li>
  </ul>
  <ul class="nav navbar-nav align-items-center ml-auto">

    <!-- ? Language selection | Uncomment if needed-->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span
          class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>
    <!--/ Language selection -->
    <!-- Search -->
    <app-navbar-search></app-navbar-search>
    <!--/ Search -->

    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->

    <!-- Notification -->
    <app-navbar-notification></app-navbar-notification>
    <!--/ Notification -->


    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">Aamod Thakur</span><span class="user-status">Operations
            Manager</span>
        </div>
       

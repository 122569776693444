<!-- <p>spinner works!</p> -->
<!-- <div class="loader--roller" *ngIf="showSpinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div> -->

  <div class="loader" *ngIf="showSpinner">
    
  </div>